import './style.scss';
import Layout from '../../component/layout';
import SlideShow from '../../component/slideShow/slideShow';
import { useEffect, useState } from 'react';
import { getApiRequest } from '../../services/apiRequest';
import Paragraph from '../../component/paragraph';


function AboutUs() {
  const [sliders, setSliders] = useState([])
  const [isLoding, setIsLoading] = useState(false)
  const [aboutUsParagraph, setAboutUsParagraph] = useState([])
  const [lang, setLang] = useState('ar')
  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar')
    }
    let language = localStorage.getItem('lang') || 'ar'
    setLang(language)
  }, [])
  useEffect(async () => {
    const sliders = await getApiRequest("/sliders", { location: 'HOME' })
    if (sliders?.data) {
      setSliders(sliders.data)
    }
    const aboutUsParagraph = await getApiRequest("/paragraphs", {
      location: 'ABOUT_US'
    })
    {
      if (aboutUsParagraph?.data) {
        setAboutUsParagraph(aboutUsParagraph.data)
        setIsLoading(true)
      }
    }
  }, [])

  return (
    isLoding &&
    <>
      <Layout>
        <div className="about_us_cointainer">

          <div className="slide_show_container">
            <SlideShow sliders={sliders} />
          </div>
         <Paragraph title={aboutUsParagraph[0][`title_${lang}`]} body={aboutUsParagraph[0][`description_${lang}`]}/>
        </div>
      </Layout>
    </>
  );
}

export default AboutUs;
