import { forwardRef, useEffect, useState } from 'react';
import FormLayout from '../../../component/admin/formLayout';
import { deleteApiRequest, getApiRequest, postApiRequest, putApiRequest } from '../../../services/apiRequest';
import AdminLayout from '../layout';
import './style.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import InputBaseWithLable from '../../../component/admin/inputBaseWithLabel';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
function Menus({ history }) {
    const [isLoading, setIsLoading] = useState(false)
    const [menus, setMenus] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [nameAr, setNameAr] = useState('')
    const [nameEn, setNameEn] = useState('')
    const [menuId, setMenuId] = useState('')
    const [order, setOrder] = useState('')
    const [link, setLink] = useState('')
    const [currentIndex, setCurrentIndex] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false)
    const [addNewMenuModalFlag, setAddNewMenuModalFlag] = useState(false)
    const [snakBarStatus, setSnakBarStatus] = useState(false)
    const [successStatus, setSuccessStatus] = useState('')
    const [alertText, setAlertText] = useState('')
    const getMenues = async () => {
        const menus = await getApiRequest('/menus')
        if (menus?.data) {
            setMenus(menus.data)
        }
        setIsLoading(true)
    }
    useEffect(async () => {
        getMenues()
    }, []) // Second way to refresh the data after updat from modal by using the modalOpen as an trigger

    return (
        isLoading &&
        <AdminLayout history={history}>
            <FormLayout name="Menus">
                <div className="menus_table_container" >
                    <div className="maenus_header_container">
                        <div className="item_header">Arabic Name</div>
                        <div className="item_header">English Name</div>
                        <div className="item_header">Order</div>
                        <div className="item_header">Action </div>
                    </div>
                    {
                        menus.map((item, i) => {
                            return (
                                <div key={item._id} className={`item_container ${i % 2 !== 0 && 'background_second'}`} >
                                    <div className="item">{item.name_ar}</div>
                                    <div className="item">{item.name_en}</div>
                                    <div className="item">{item.order}</div>
                                    <div className="item flex_row">
                                        <DeleteIcon
                                            className='cursor_pointer delete_icon'
                                            onClick={
                                                () => {
                                                    setMenuId(item._id)
                                                    setDialogOpen(true)
                                                }
                                            }
                                        />
                                        <EditIcon className='cursor_pointer edit_icon'
                                            onClick={
                                                () => {
                                                    setMenuId(item._id)
                                                    setNameAr(item.name_ar)
                                                    setNameEn(item.name_en)
                                                    setOrder(item.order)
                                                    setLink(item.link)
                                                    setCurrentIndex(i)
                                                    setModalOpen(true)
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="new_menu_button">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                // let tmpArray = [].concat(phoneNumbers)
                                // tmpArray.push('')
                                // setPhoneNumbers(tmpArray)
                                setMenuId('')
                                setNameAr('')
                                setNameEn('')
                                setOrder('')
                                setLink('')
                                setAddNewMenuModalFlag(true)
                            }
                        }

                    >
                        Add New Menu
                </Button>
                </div>

            </FormLayout>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="modal_container">
                    <InputBaseWithLable
                        label="Arabic Name"
                        placeholder="Arabic Name"
                        value={nameAr}
                        onChange={(e) => {
                            setNameAr(e.target.value)
                        }}
                    />

                    <InputBaseWithLable
                        label="English Name"
                        placeholder="English Name"
                        value={nameEn}
                        onChange={(e) => {
                            setNameEn(e.target.value)
                        }}
                    />
                    <InputBaseWithLable
                        label="Link"
                        placeholder="Link"
                        value={link}
                        onChange={(e) => {
                            setLink(e.target.value)
                        }}
                    />
                    <InputBaseWithLable
                        label="Order"
                        placeholder="Order"
                        value={order}
                        onChange={(e) => {
                            setOrder(e.target.value)
                        }}
                    />
                    <div className="update_now_button">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                async () => {
                                    const updateMenus = await putApiRequest(`/menus/${menuId}`, {
                                        menu: {
                                            name_ar: nameAr,
                                            name_en: nameEn,
                                            order: order,
                                            link
                                        }
                                    })
                                    if (updateMenus.success) {
                                        // First way to refresh the data after updat from modal
                                        let temp = [].concat(menus)
                                        let index = temp.findIndex((item) => item._id === menuId)
                                        temp[index].name_ar = nameAr
                                        temp[index].name_en = nameEn
                                        temp[index].order = order
                                        temp[index].link = link
                                        setMenus(temp)
                                        setModalOpen(false)
                                        setSnakBarStatus(true)
                                        setSuccessStatus('success')
                                        setAlertText('Menu Updated Successfuly !')
                                    } else {
                                        setSnakBarStatus(true)
                                        setSuccessStatus('error')
                                        setAlertText('Ooops....Something went Wrong !')
                                    }
                                }
                            }
                        >
                            Update NOW !
                </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={addNewMenuModalFlag}
                onClose={() => setAddNewMenuModalFlag(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="modal_container">
                    <div className='flex_col_center_center'> Add New Menu</div>
                    <InputBaseWithLable
                        label="Arabic Name"
                        placeholder="Arabic Name"
                        value={nameAr}
                        onChange={(e) => {
                            setNameAr(e.target.value)
                        }}
                    />

                    <InputBaseWithLable
                        label="English Name"
                        placeholder="English Name"
                        value={nameEn}
                        onChange={(e) => {
                            setNameEn(e.target.value)
                        }}
                    />
                    <InputBaseWithLable
                        label="Link"
                        placeholder="Link"
                        value={link}
                        onChange={(e) => {
                            setLink(e.target.value)
                        }}
                    />
                    <InputBaseWithLable
                        label="Order"
                        placeholder="Order"
                        value={order}
                        onChange={(e) => {
                            setOrder(e.target.value)
                        }}
                    />
                    <div className="update_now_button">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                async () => {
                                    const updateMenus = await postApiRequest(`/menus`, {
                                        name_ar: nameAr,
                                        name_en: nameEn,
                                        order: order,
                                        link
                                    })
                                    if (updateMenus.success) {
                                        // First way to refresh the data after updat from modal
                                        let temp = [].concat(menus)
                                        temp.push(updateMenus.data.menu)
                                        setMenus(temp)
                                        setAddNewMenuModalFlag(false)
                                        setSnakBarStatus(true)
                                        setSuccessStatus('success')
                                        setAlertText('New Menu has been created Successfuly !')
                                    } else {
                                        setSnakBarStatus(true)
                                        setSuccessStatus('error')
                                        setAlertText('Ooops....Something went Wrong !')
                                    }
                                }
                            }
                        >
                            Add NOW !
                </Button>
                    </div>
                </div>
            </Modal>
            <Dialog
                open={dialogOpen}
                keepMounted
                onClose={
                    () => {
                        setDialogOpen(false)
                    }
                }
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className='red_button'>Delete !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this Menus ?!
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={
                        () => {
                            setDialogOpen(false)
                        }
                    }
                        color="primary">
                        <div className="green_button">Cancle</div>
                    </Button>
                    <Button onClick={
                        async () => {
                            const deleteMenu = await deleteApiRequest(`/menus/${menuId}`)
                            if (deleteMenu.success) {
                                let tmp = [].concat(menus)
                                let temp2 = menus.filter((menu) => menu._id !== menuId)
                                setMenus(temp2)
                                setSnakBarStatus(true)
                                setSuccessStatus('success')
                                setAlertText('Menu deleted Successfuly !')
                            } else {
                                setSnakBarStatus(true)
                                setSuccessStatus('error')
                                setAlertText('Ooops....Something went Wrong !')
                            }
                            setDialogOpen(false)
                        }
                    } color="primary">
                        <div className="red_button">Delete</div>
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snakBarStatus} autoHideDuration={5000}
                onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                }
            >
                <Alert onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                } severity={successStatus}>
                    {alertText}
                </Alert>
            </Snackbar>

        </AdminLayout>
    )
}
export default Menus;