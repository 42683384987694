import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Modal, Snackbar, useScrollTrigger } from '@material-ui/core';
import { useEffect, useState } from 'react';
import FormLayout from '../../../component/admin/formLayout';
import { deleteApiRequest, getApiRequest, postApiRequest, putApiRequest } from '../../../services/apiRequest';
import AdminLayout from '../layout';
import './style.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InputBaseWithLable from '../../../component/admin/inputBaseWithLabel';
import { Alert } from '@material-ui/lab';
import { getQuery } from '../../../services/utility';
import Pagination from '../../../component/pagination';
import WYSIWYGCKEditor from '../../../component/admin/DraftEditor';
import Upload from '../../../component/admin/upload';
function StudentError({ history }) {
    let forms = getQuery('forms') || []

    return (
        <div className="menus_table_container" >
            <div className="maenus_header_container">
                <div className="item_header">Question</div>
                <div className="item_header">Student Answer</div>
                <div className="item_header">Correct Answer </div>
            </div>


            {
                JSON.parse(forms).filter((item) => item.isTrue === false).map(
                    (form, index3) => {
                        return (
                            <>
                                <div key={index3} className={`item_container ${index3 % 2 !== 0 && 'background_second'}`}>
                                    <div className="item">  {form.question} </div>
                                    <div className="item">{form.answer}</div>
                                    <div className="item flex_row">{form.correctAnswer}</div>
                                </div>
                            </>
                        )
                    }
                )

            }
        </div>
    )
}
export default StudentError;