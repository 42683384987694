import React, { useState } from "react"
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';

var stateFromHTML = require('draft-js-import-html').stateFromHTML;

export default function WYSIWYGCKEditor({ value, cb }) {

  const [editorState, seteditorState] = useState(EditorState.createWithContent(stateFromHTML(value)))
  const onEditorStateChange = (value) => {
    seteditorState(value)
  }
  return (
    <>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        onChange={() => {
          cb(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        }}
      />
    </>
  )
}