import { useEffect, useState } from "react";
import "./style.scss";
import AOS from 'aos'
import ReactHtmlParser from 'react-html-parser';
function Paragraph({ title, body }) {
  //  const newText = body.split('\n').map(str => <p>{str}</p>);
  const [lang, setLang] = useState('ar')
  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar')
    }
    let language = localStorage.getItem('lang') || 'ar'
    setLang(language)
  }, [])
    useEffect(() => {
        AOS.init({
            duration: 2000
        })
    }, [])
    return (
        <div className={ lang === 'en' ? 'paragraph_container' : "paragraph_container rtl"}
            data-aos="fade-down">
            <div className="title">
                {title}
            </div>
            <div className="body">
                {ReactHtmlParser(body)}
            </div>
        </div>
    );
}
export default Paragraph;