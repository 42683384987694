import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SnapChat from '../../snapchat.jpg'
import './style.scss'
import WhatsAppLogo from '../../wats.png'
import { WhatsApp } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import CallIcon from '@material-ui/icons/Call';
import AOS from 'aos';
function Footer({ settings }) {
    const [lang, setLang] = useState('ar')
    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'ar')
        }
        let language = localStorage.getItem('lang') || 'ar'
        setLang(language)
    }, [])
    useEffect(() => {
        AOS.init({
            duration: 2000
        })
    }, [])
    return (
        <>
            <div className="footer_container">
                <div className="left_panel">
                    <div> <a href={settings.twitter_link} target=""><TwitterIcon className="social_icon" /></a></div>
                    <div> <a href={settings.facebook_link} target=""><FacebookIcon className="social_icon" /></a></div>
                    <div> <a href={settings.instagram_link} target=""> <InstagramIcon className="social_icon" /></a></div>
                    <div> <a href={settings.youtube_link} target=""><YouTubeIcon className="social_icon" /></a> </div>
                    <div> <a href={settings.snapchat_link} target=""><img src={SnapChat} className="social_icon" width='30px' height='30px' /></a></div>
                </div>
                <div className="right_panel">
                    <div className="company_name">
                        <div className="details">{settings[`name_${lang}`]}</div>
                        <div className="details"><LocationOnIcon />  {settings.our_location}</div>
                        {settings.contact_number.map(
                            (number) => {
                                return (<div className="details"> <PhoneAndroidIcon />  {number}</div>)
                            }
                        )}
                    </div>
                </div>
                <div className="fixed_logo">
                    <div className='whatsapp_container' style={{ marginBottom: 10, cursor: 'pointer' }}>
                        <a href={`tel:${settings?.hot_business_phone_number}`} ><CallIcon className="whatsapp" /></a>
                    </div>
                    <div className='whatsapp_container'>
                        <a href={`https://api.whatsapp.com/send?&phone=${settings?.whatsApp_number}&text=I%20am%20interested,%20please%20communicate%20with%20me`} target="_blank"><WhatsApp className="whatsapp" /></a>
                    </div>
                    {
                        lang === 'en' ?
                            <div className='whatsapp_container' style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => {
                                localStorage.setItem('lang', 'ar')
                                window.location.reload()
                            }}>
                                <div className="lang">عربي</div>
                            </div> :
                            <div className='whatsapp_container' style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => {
                                localStorage.setItem('lang', 'en')
                                window.location.reload()
                            }}>
                                <div className="lang">En</div>
                            </div>
                    }
                </div>

            </div>
        </>
    )
}
export default Footer;