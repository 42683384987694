import './style.scss';
import Layout from "../../component/layout";
import { Button, Grid, Hidden, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getApiRequest, postApiRequest } from '../../services/apiRequest';
import { getQuery } from '../../services/utility';
import Paragraph from '../../component/paragraph';
import ContactForm from '../../component/ContactForm';
import AOS from 'aos';
function SubCourses() {
    const course_id = getQuery("id")
    const [subCourse, setSubCourse] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [course, setCourse] = useState('')
    const [lang, setLang] = useState('ar')
    useEffect(async () => {
        if (!localStorage.getItem('lang')) {
          localStorage.setItem('lang', 'ar')
        }
        let language = localStorage.getItem('lang') || 'ar'
        setLang(language)
        if (course_id) {
            const subcourse = await getApiRequest(`/sub-course/${course_id}`)
            if (subcourse?.data) {
                setSubCourse(subcourse.data)
                setCourse(subcourse?.data.course[`name_${language}`])
                setIsLoading(true)
            }
        }
        AOS.init({
            duration: 2000
        })
    }, [course_id])
    return (
        isLoading &&
        <>
            <Layout >
                <Hidden only={['xs', 'sm']}>
                    <div className="objectives_main_container" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }} >
                        <div className="company_name"> {course}  </div>
                    </div>
                </Hidden >
                <Grid container  >

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="course_image" data-aos="fade-down-right"> <img src={subCourse.cover_image}></img>  </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container  >
                            <Grid item xs={12} sm={12} md={8} lg={8} style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                                <Paragraph title={subCourse[`name_${lang}`]} body={subCourse[`description_${lang}`]} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <div className='sub_course_book_now_container' style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                                    <ContactForm course_id={course_id} title={lang === 'en' ? "Book Now" : "احجز الان"} submitText={lang === 'en' ? "Book Now" : "احجز الان"} requestType='book' />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Layout>
        </>
    );
}
export default SubCourses;