import './style.scss';
import Layout from "../../component/layout";
import { useEffect, useState } from 'react';
import { getApiRequest } from '../../services/apiRequest';
import { Hidden } from '@material-ui/core';
import { getQuery } from '../../services/utility';
import CoursesView from '../../component/coursesView';
import AOS from 'aos';
function Course() {
    const [settings, setSettings] = useState({})
    const [isloading, setIsLoading] = useState(false)
    const [sliders, setSliders] = useState([])
    const [paragraphs, seParagraphs] = useState([])
    const [courses, setCourses] = useState({})
    const [subCourses, setSubCourses] = useState({})
    const course_id = getQuery('id')
    const [course, setCourse] = useState({})
    const [lang, setLang] = useState('ar')
  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar')
    }
    let language = localStorage.getItem('lang') || 'ar'
    setLang(language)
  }, [])
    useEffect(async () => {
        const settings = await getApiRequest("/settings")
        if (settings?.data)
            setSettings(settings.data)
    }, [])
    useEffect(async () => {
        const subCourses = await getApiRequest("/sub-course/by-course", { course: course_id })
        if (subCourses?.data) {
            setSubCourses(subCourses.data)
            setCourse(subCourses?.data[0]?.course)
            setIsLoading(true)
        }
        AOS.init({
            duration: 2000
        })
    }, [course_id])
    return (
        isloading &&
        <>
            <Layout>
                <Hidden only={['xs', 'sm']}>
                    <div className="objectives_main_container" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                        <div className="company_name"> {course[`name_${lang}`]} </div>
                    </div>
                </Hidden>
                <div className="sub_course_container" data-aos="flip-left">
                    <CoursesView courses={subCourses} link="sub-course?id=" />
                </div>
            </Layout>
        </>
    );
}
export default Course;