import { Button, TextField } from "@material-ui/core";
import { useState } from "react";
import "./admin_menu.scss";
import { postApiRequest } from "../services/apiRequest";
function AdminMenu() {
    const [nameAr, setNameAr] = useState('')
    const [nameEn, setNameEn] = useState('')
    const [link, setLink] = useState('')
    const [order, setOrder] = useState(0)
    return (
        <div className="admin_menu_container">
            <TextField
                id="standard-basic"
                label="name_ar"
                onChange={(event) => {
                    setNameAr(event.target.value)
                }}
            />
            <TextField
                id="standard-basic"
                label="name_en"
                onChange={(event) => {
                    setNameEn(event.target.value)
                }}
            />
            <TextField
                id="standard-basic"
                label="link"
                onChange={(event) => {
                    setLink(event.target.value)
                }}
            />
            <TextField
                id="standard-basic"
                label="order"
                type="number"
                onChange={(event) => {
                    setOrder(event.target.value)
                }}
            />
            <Button
                variant="contained"
                onClick={async () => {
                    const Menu = await postApiRequest("/menus", {
                        name_ar: nameAr,
                        name_en : nameEn,
                        link,
                        order
                    })
                    //console.log("name_ar", nameAr)
                }}
            >add Menu</Button>
        </div>
    )
}
export default AdminMenu;