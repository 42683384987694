import Header from '../header';
import Footer from '../fotter';
import './style.scss';
import { useEffect, useState } from 'react';
import { getApiRequest } from '../../services/apiRequest';
import { Hidden } from '@material-ui/core';
function Layout({ title, settingCB = () => { }, ...props }) {
    const [settings, setSettings] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [lang, setLang] = useState('ar')
  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar')
    }
    let language = localStorage.getItem('lang') || 'ar'
    setLang(language)
  }, [])
    useEffect(async () => {
        const setting = await getApiRequest("/settings")
        if (setting?.data) {
            document.title = `${setting.data.name_ar} - ${setting.data[`name_${lang}`]}`
            setSettings(setting.data)
            setIsLoading(true)
            settingCB(setting.data)
        }
    }, [])
    return (
        isLoading && <div className="main_container">
            <Header settings={settings} />
            <div className="children">{props.children}</div>
            <Footer settings={settings} />
        </div>
    );

}
export default Layout;