import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter
} from "react-router-dom";
import Home from './Home'
import News from './News';
import AboutUs from './pages/aboutUs';
import Course from './pages/Course';
import AdminTest from './dbInsertion/admin_test';
import AdminMenu from './dbInsertion/admin_menu';
import AdminCourse from './dbInsertion/admin_course';
import AdminSubCourse from './dbInsertion/admin_subCourse';
import SubCourses from './pages/sub-courses';
import Article from './pages/article';
import Articles from './pages/articles';
import LogIn from './pages/admin/login';
import Admin from './pages/admin'
import Settings from './pages/admin/settings';
import Menus from './pages/admin/menus';
import Admins from './pages/admin/admins';
import Employees from './pages/admin/employees';
import AdminArticles from './pages/admin/Admin-articles';
import AdminCourses from './pages/admin/Courses';
import Sliders from './pages/admin/sliders';
import MessageCenter from './pages/admin/MessageCenter';
import LogInEmployee from './pages/admin/login-employee';
import MessageCenterEmployee from './pages/admin/MessageCenterEmployee';
import EmployeeArticles from './pages/admin/Employee-articles';
import PeopleOpinion from './pages/admin/people-opinion';
import PeopleOpinionPage from './pages/peopleOpinion';
import Questions from './pages/admin/questions';
import AssessmentTest from './pages/admin/assessmentTest/assessmentTest';
import NewAssessmentTest from './pages/test/new-test/new-assessment-test';
import StartTestNow from './pages/test/start-test';
import Report from './pages/admin/assessmentTest/report';
import StudentError from './pages/admin/error-student';
import HttpsRedirect from 'react-https-redirect';

ReactDOM.render(
  <HttpsRedirect>
  <Router>
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/about-us">
        <AboutUs />
      </Route>
      <Route path="/people-opinion">
        <PeopleOpinionPage />
      </Route>
      <Route path="/course" >
        <Course />
      </Route>
      <Route path="/AdminTest" component={withRouter(AdminTest)} />
      <Route path="/AdminMenu" >
        <AdminMenu />
      </Route>
      <Route path="/AdminCourse">
        <AdminCourse />
      </Route>
      <Route path="/sub-courses" component={withRouter(AdminSubCourse)} />
      <Route path="/sub-course">
        <SubCourses />
      </Route>
      <Route path="/article">
        <Article />
      </Route>
      <Route path="/news">
        <Articles />
      </Route>
      <Route path="/admin/login" component={withRouter(LogIn)} />
      <Route exact path="/admin" component={withRouter(Settings)} />
      <Route path="/admin/settings" component={withRouter(Settings)} />
      <Route path="/admin/menus" component={withRouter(Menus)} />
      <Route path="/admin/admins" component={withRouter(Admins)} />
      <Route path="/admin/employees" component={withRouter(Employees)} />
      <Route path="/admin/articles" component={withRouter(AdminArticles)} />
      <Route path="/admin/courses" component={withRouter(AdminCourses)} />
      <Route path="/admin/sliders" component={withRouter(Sliders)} />
      <Route path="/admin/message-center" component={withRouter(MessageCenter)} />
      <Route path="/admin/employee-login" component={withRouter(LogInEmployee)} />
      <Route path="/admin/message-center-employee" component={withRouter(MessageCenterEmployee)} />
      <Route path="/admin/employee-articles" component={withRouter(EmployeeArticles)} />
      <Route path="/admin/people-opinion" component={withRouter(PeopleOpinion)} />
      <Route path="/admin/questions" component={withRouter(Questions)} />
      <Route path="/admin/assessment-test" component={withRouter(AssessmentTest)} />
      <Route path="/test/new-test" component={withRouter(NewAssessmentTest)} />
      <Route exact path="/test/start-test" component={withRouter(StartTestNow)} />
      <Route path="/admin/report" component={withRouter(Report)} />
      <Route path="/admin/error-student" component={withRouter(StudentError)} />
      
    </Switch>
  </Router>
  </HttpsRedirect>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
