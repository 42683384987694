import { Button, FormControl, Grid, Input, InputBase, InputLabel, OutlinedInput, Snackbar, TextField } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import { useEffect, useState } from "react";
import FormLayout from "../../../component/admin/formLayout";
import InputBaseWithLable from "../../../component/admin/inputBaseWithLabel";
import { getApiRequest, putApiRequest } from "../../../services/apiRequest";
import AdminLayout from "../layout";
import './style.scss'
import Modal from '@material-ui/core/Modal';
import { Alert } from "@material-ui/lab";
import WYSIWYGCKEditor from "../../../component/admin/DraftEditor";

function Settings({ history }) {
    const [setting, setSettings] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [nameEn, setNameEn] = useState(setting.name_en || '')
    const [nameAr, setnameAr] = useState('')
    const [ourLocation, setOurLocation] = useState('')
    const [facebookLink, setFacebookLink] = useState('')
    const [twitterLink, setTwitterLink] = useState('')
    const [youtubelink, setYoutubelink] = useState('')
    const [instagramlink, setInstagramlink] = useState('')
    const [snapchatlink, setSnapchatlink] = useState('')
    const [map, setMap] = useState('')
    const [phoneNumbers, setPhoneNumbers] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [phonenumber, setPhonenumber] = useState('')
    const [snakBarStatus, setSnakBarStatus] = useState(false)
    const [successStatus, setSuccessStatus] = useState('')
    const [alertText, setAlertText] = useState('')
    const [description_ar, setDescription_ar] = useState('')
    const [description_en, setDescription_en] = useState('')
    const [whatsAppNumber, setWhatsAppNumber] = useState('')
    const [hotBusinessPhoneNumber, setHotBusinessPhoneNumber] = useState('')

    useEffect(async () => {
        const setting = await getApiRequest('/settings')
        if (setting?.data) {
            setSettings(setting.data)
            setNameEn(setting.data.name_en)
            setnameAr(setting.data.name_ar)
            setOurLocation(setting.data.our_location)
            setFacebookLink(setting.data.facebook_link)
            setTwitterLink(setting.data.twitter_link)
            setYoutubelink(setting.data.youtube_link)
            setInstagramlink(setting.data.instagram_link)
            setSnapchatlink(setting.data.snapchat_link)
            setMap(setting.data.map)
            setPhoneNumbers(setting.data.contact_number)
            setWhatsAppNumber(setting?.data?.whatsApp_number)
            setHotBusinessPhoneNumber(setting?.data?.hot_business_phone_number)
        }
        const paragraph = await getApiRequest('/paragraphs', { location: 'ABOUT_US' })
        if (paragraph.data) {
            setDescription_ar(paragraph.data[0].description_ar)
            setDescription_en(paragraph.data[0].description_en)
        }
        setIsLoading(true)
    }, [])
    return (
        isLoading &&
        <AdminLayout history={history}>
            <FormLayout name="Settings">
                <Grid container spacing={3} className="company_name_container">
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputBaseWithLable
                            label="English Name"
                            placeholder="English Name"
                            value={nameEn}
                            onChange={(e) => {
                                setNameEn(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputBaseWithLable
                            label="Arabic Name"
                            placeholder="Arabic Name"
                            value={nameAr}
                            onChange={(e) => {
                                setnameAr(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                <div className="divider"></div>
                <Grid container spacing={3} className="company_name_container" >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputBaseWithLable
                            label="Company Location"
                            placeholder="Company Location"
                            value={ourLocation}
                            onChange={(e) => {
                                setOurLocation(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputBaseWithLable
                            label="Google Maps - Link"
                            placeholder="Google Maps"
                            value={map}
                            onChange={(e) => {
                                setMap(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                <div className="divider"></div>
                <Grid container spacing={3} className="company_name_container" >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputBaseWithLable
                            label="WhatsApp Number"
                            placeholder="WhatsApp Number"
                            value={whatsAppNumber}
                            onChange={(e) => {
                                setWhatsAppNumber(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputBaseWithLable
                            label="Hot business phone number"
                            placeholder="Hot business phone number"
                            value={hotBusinessPhoneNumber}
                            onChange={(e) => {
                                setHotBusinessPhoneNumber(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                <div className="divider"></div>
                <Grid container spacing={3} className="company_name_container" >
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBaseWithLable
                            label="Facebook link"
                            placeholder="Facebook link"
                            value={facebookLink}
                            onChange={(e) => {
                                setFacebookLink(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBaseWithLable
                            label="Twitter Link"
                            placeholder="Twitter Link"
                            value={twitterLink}
                            onChange={(e) => {
                                setTwitterLink(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBaseWithLable
                            label="Youtube link"
                            placeholder="Youtube link"
                            value={youtubelink}
                            onChange={(e) => {
                                setYoutubelink(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBaseWithLable
                            label="Instgram link"
                            placeholder="Instgram link"
                            value={instagramlink}
                            onChange={(e) => {
                                setInstagramlink(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBaseWithLable
                            label="Snapchat link"
                            placeholder="Snapchat link"
                            value={snapchatlink}
                            onChange={(e) => {
                                setSnapchatlink(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                <div className="divider"></div>
                <Grid container spacing={3} className="company_name_container" >

                    {
                        phoneNumbers.map((number, i) => {
                            return (
                                <Grid key={i} item xs={12} sm={12} md={4} lg={4}>
                                    <div className='input_container'>
                                        <div
                                            className='delet_from_array_icon'
                                            onClick={() => {
                                                let tmpArray = [].concat(phoneNumbers)
                                                tmpArray = tmpArray.filter(
                                                    (item, index) => {
                                                        return i != index
                                                    }
                                                )
                                                setPhoneNumbers(tmpArray)
                                            }}
                                        >X</div>
                                        <InputBaseWithLable
                                            label={`Phone Number ${i + 1}`}
                                            placeholder="Phone Number"
                                            value={number}
                                            onChange={(e) => {
                                                /// take copy from base array
                                                // in lodash we use clone() function
                                                let tmpArray = [].concat(phoneNumbers)
                                                tmpArray[i] = e.target.value
                                                setPhoneNumbers(tmpArray)
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <div className="add_number_button">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                // let tmpArray = [].concat(phoneNumbers)
                                // tmpArray.push('')
                                // setPhoneNumbers(tmpArray)
                                setModalOpen(true)
                            }
                        }

                    >
                        +
                    </Button>
                </div>
                <div className="partition " style={{ marginTop: '20px' }}>
                    Contact US -  English Description
                </div>
                <WYSIWYGCKEditor
                    value={description_en}
                    cb={
                        (value) => {
                            setDescription_en(value)
                        }
                    }
                />
                <div className="partition " style={{ marginTop: '20px' }}>
                    Contact US -   Arabic Description
                </div>
                <WYSIWYGCKEditor
                    value={description_ar}
                    cb={
                        (value) => {
                            setDescription_ar(value)
                        }
                    }
                />
                <div className="update_button">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            async () => {
                                const updateSettings = await putApiRequest('/settings', {
                                    settings: {
                                        name_en: nameEn,
                                        name_ar: nameAr,
                                        our_location: ourLocation,
                                        facebook_link: facebookLink,
                                        twitter_link: twitterLink,
                                        youtube_link: youtubelink,
                                        instagram_link: instagramlink,
                                        snapchat_link: snapchatlink,
                                        contact_number: phoneNumbers,
                                        whatsApp_number: whatsAppNumber,
                                        hot_business_phone_number: hotBusinessPhoneNumber
                                    },
                                    description_en,
                                    description_ar
                                })
                                if (updateSettings.success) {
                                    setSnakBarStatus(true)
                                    setSuccessStatus("success")
                                    setAlertText("Data updated successfuly !")
                                } else {
                                    setSnakBarStatus(true)
                                    setSuccessStatus("error")
                                    setAlertText("Some thing went wrogn ?!")
                                }
                            }
                        }
                    >
                        Update Now
                    </Button>
                </div>
            </FormLayout>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className='modal_container'>
                    {/* <h2 className="title">Please Fill the Number</h2> */}
                    <InputBaseWithLable
                        label={`Phone Number`}
                        placeholder="Phone Number"
                        value={phonenumber}
                        onChange={(e) => {
                            setPhonenumber(e.target.value)
                        }}
                    />
                    <div className="add_number_button_modal">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    let tmpArray = [].concat(phoneNumbers)
                                    tmpArray.push(phonenumber)
                                    setPhoneNumbers(tmpArray)
                                    setPhonenumber('')
                                    setModalOpen(false)
                                }
                            }
                        >
                            Add Number Now
                        </Button>
                    </div>
                </div>
            </Modal>
            <Snackbar open={snakBarStatus} autoHideDuration={5000}
                onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                }
            >
                <Alert onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                } severity={successStatus}>
                    {alertText}
                </Alert>
            </Snackbar>
        </AdminLayout>
    )
}
export default Settings;