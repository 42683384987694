import './style.scss';
import Layout from "../../component/layout";
import { useEffect, useState } from 'react';
import { getApiRequest } from '../../services/apiRequest';
import { Hidden, Modal } from '@material-ui/core';
import { getQuery } from '../../services/utility';
import Paragraph from '../../component/paragraph';
import CoursesView from '../../component/coursesView';
import { Link } from 'react-router-dom';
import Pagination from '../../component/pagination';
import AOS from 'aos';
import ReactPlayer from 'react-player';
function PeopleOpinion() {
    const [peopleOpinion, setPeopleOpinion] = useState([])
    const [isloading, setIsLoading] = useState(false)
    const [totalpages, setTotalPages] = useState(1)
    const [modalOpen, setModalOpen] = useState(false)
    const [videoLink, setVideoLink] = useState('')
    let page = getQuery('page') || 1
    const [lang, setLang] = useState('ar')
    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'ar')
        }
        let language = localStorage.getItem('lang') || 'ar'
        setLang(language)
    }, [])
    useEffect(async () => {
        const peopleOpinions = await getApiRequest(`/people-opinion`, { page })
        if (peopleOpinions?.data) {
            setTotalPages(peopleOpinions.data.totalPage)
            setPeopleOpinion(peopleOpinions.data.docs)
        }
        setIsLoading(true)
        AOS.init({
            duration: 2000
        })
    }, [page])
    return (
        isloading &&
        <>
            <Layout>
                <Hidden only={["xs"]} >
                    <div className="article_main_container">
                        <div className="article_company_name"> {lang === 'en' ? "Our Amazing People" : "طلابنا الاعزاء"} </div>
                    </div>
                </Hidden>
                <div data-aos="flip-left">
                    <CoursesView courses={peopleOpinion} link="" cover_image={'image_link'} CB={(url) => {
                       setVideoLink(url)
                       setModalOpen(true)
                    }} />
                </div>
                <Pagination page={page} totalpages={totalpages} maxNumberOfPages={4} />
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="vid_player">
                        <ReactPlayer
                            url={videoLink}
                            width="100%"
                            height="100%"
                            playing={false}
                            controls
                            volume={1}
                        />

                    </div>
                </Modal>
            </Layout>
        </>
    );
}
export default PeopleOpinion;