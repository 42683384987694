import axios from 'axios'

const apiRequest = async (method = 'GET', url, data = {}, progress = () => { }) => {
    try {
        ///http://3.142.246.17:3001/
       // const SERVER = "http://3.142.246.17:3001" AWS
           const SERVER = "https://tobeenglish-apo.herokuapp.com"
        
        //    const SERVER = "http://localhost:3001"

        const options = {
            method,
            url: `${SERVER}${url}`,
            data,
            responseType: 'json',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Berear ' + localStorage.getItem('token')
            }
        }
        if (method === 'GET') {
            delete options['data']
            options.params = {
                ...data
            }
        }

        if (method === 'UPLOAD') {
            const response = await axios({
                ...options,
                method: 'POST',
                onUploadProgress: (ProgressEvent) => {
                    let progressPercent = Math.round(
                        ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
                    progress(progressPercent)
                }
            })
            if (response) {
                return ({
                    success: true,
                    status: response?.status,
                    message: response?.data?.message,
                    data: response?.data
                })
            }
        } else {
            const response = await axios(options)
            if (response) {
                return ({
                    success: true,
                    status: response?.status,
                    message: response?.data?.message,
                    data: response?.data
                })
            }
        }
    } catch (err) {
        return ({
            success: false,
            status: err?.response?.status,
            message: err?.response?.data?.message,
            errors: err?.response?.data?.errors
        })
    }
}

export const getApiRequest = async (url, data) => {
    return await apiRequest('GET', url, data)
}
export const postApiRequest = async (url, data) => {
    return await apiRequest('POST', url, data)
}
export const putApiRequest = async (url, data) => {
    return await apiRequest('PUT', url, data)
}
export const deleteApiRequest = async (url, data = {}) => {
    return await apiRequest('DELETE', url, data)
}
export const uploadApiRequest = async (url, data, progress) => {
    return await apiRequest('UPLOAD', url, data, progress)
}

export default apiRequest