import './style.scss';
import MenuIcon from '@material-ui/icons/Menu';
import { useEffect, useState } from 'react';
import { Slide, SwipeableDrawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LeftDrawer from '../leftDrawer';
const MobileHeader = ({
    settings,
    menus,
    courses = []
}) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [lang, setLang] = useState('ar')
    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'ar')
        }
        let language = localStorage.getItem('lang') || 'ar'
        setLang(language)
    }, [])
    return (
        <>
            <div className="mobile_header_container">
                <div className="menu_burger_container" onClick={() => setIsDrawerOpen(true)}>
                    <MenuIcon className="menu_icon" />
               {lang === 'en' ? "Menu" : "القوائم"}
            </div>
                <div className="mobile_header_company_name">
                    <img className="logo" src={settings.logo} />
                </div>
            </div>
            <LeftDrawer type="mobile" courses={courses} menus={menus} isDrawerOpen={isDrawerOpen} onCloseCB={() => setIsDrawerOpen(false)} />
        </>
    );
}

export default MobileHeader;
