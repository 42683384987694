import './style.scss'
import Alert from '@material-ui/lab/Alert';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, TextField } from '@material-ui/core';
import { postApiRequest } from '../../services/apiRequest';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { getQuery } from '../../services/utility';
function ContactFormTest(
    {
        std,
        title,
        requestType,
        submitText
    }
) {
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('971')
    const [errorCode, setErrorCode] = useState('')
    const [lang, setLang] = useState('ar')
    const [dialogOpen, setDialogOpen] = useState(false)
    const [createdId, setCreatedId] = useState('')
    const [existStd, setExistStd] = useState({})
    const [emarah, setEmarah] = useState('Dubai')
    let showResult = getQuery('show_result') || 'false'
    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'ar')
        }
        let language = localStorage.getItem('lang') || 'ar'
        setLang(language)
    }, [])
    return (
        <>
            <div className="book_test_form_container">
                <div className="book_container">
                    <div className="book_now"> {title} </div>
                </div>
                <div className="filed">
                    <TextField id="full_name" label={lang === 'en' ? "Name" : "الاسم"} variant="outlined" className="textfield"
                        onChange={
                            (event) => {
                                setFullName(event.target.value)
                            }
                        } />
                </div>
                <div className="filed">
                    <TextField id="email" label={lang === 'en' ? "E-mail" : "البريد الالكروني"} variant="outlined" className="textfield"
                        onChange={
                            (event) => {
                                setEmail(event.target.value)
                            }
                        }
                    />
                </div>
                {
                    lang !== 'en' ?
                        <div className="filed">
                            الامارة :
                        <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue="Dubai"
                                value={emarah}
                                className="textfield"
                                onChange={
                                    (e) => {
                                        setEmarah(e.target.value)
                                        //console.log("e.target.value", e.target.value)
                                    }
                                }
                            >
                                <MenuItem value={'Abu Dhabi'}>ابو ظبي</MenuItem>
                                <MenuItem value={'Al ain'}>العين</MenuItem>
                                <MenuItem value={'Dubai'}>دبي</MenuItem>
                                <MenuItem value={'Sharjah'}>الشارقة</MenuItem>
                                <MenuItem value={'Ajman'}>عجمان</MenuItem>
                                <MenuItem value={'RAK'}>راس الخيمة</MenuItem>
                                <MenuItem value={'Fujairah'}>الفجيرة</MenuItem>

                            </Select>
                        </div>
                        :
                        <div className="filed">
                             Emirate :
                        <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue="Dubai"
                                value={emarah}
                                className="textfield"
                                onChange={
                                    (e) => {
                                        setEmarah(e.target.value)
                                        //console.log("e.target.value", e.target.value)
                                    }
                                }
                            >
                                <MenuItem value={'Abu Dhabi'}>Abu Dhabi</MenuItem>
                                <MenuItem value={'Al Ain'}>Al Ain</MenuItem>
                                <MenuItem value={'Dubai'}>Dubai</MenuItem>
                                <MenuItem value={'Sharjah'}>Sharjah</MenuItem>
                                <MenuItem value={'Ajman'}>Ajman</MenuItem>
                                <MenuItem value={'RAK'}>RAK</MenuItem>
                                <MenuItem value={'Fujairah'}>Fujairah</MenuItem>

                            </Select>
                        </div>
                }

                <div className="filed ">

                    <PhoneInput
                        international
                        defaultCountry="AE"
                        country='AE'
                        placeholder={lang === 'en' ? "Phone Number" : "رقم الهاتف"}
                        value={phoneNumber}
                        onChange={
                            (value) => {
                                setPhoneNumber(value)
                            }
                        }
                        style={{ width: '100%' }}
                    />


                </div>
                <div className="filed">
                    <Button variant="contained" color="primary" className="textfield"
                        onClick={
                            async () => {
                                if (fullName && email && phoneNumber != '971' && emarah && phoneNumber.length == 12 && !!parseInt(phoneNumber)) {
                                    console.log('phoneNumber', phoneNumber)
                                    const std = await postApiRequest('/students', {
                                        name: fullName,
                                        email,
                                        phone_number: phoneNumber.toString(),
                                        emarah
                                    })

                                    if (std.success == true) {
                                        // console.log("student._id",std?.data.student._id)
                                        setErrorCode("Student Has been Created Successfully")
                                        setDialogOpen(true)
                                        setCreatedId(std?.data.student._id)
                                    } else {
                                        if (std.message.includes("duplicate key")) {
                                            setErrorCode("Dublicated Email or User Name")
                                        } else {
                                            setErrorCode("Something Went Wrong ?!")
                                        }

                                    }

                                } else {
                                    setErrorCode(phoneNumber.length !=12 || !!!parseInt(phoneNumber) ? "Phone Number should be like +971564403829" : "All the above fields are required!")
                                }
                            }
                        }
                    >
                        {submitText}
                    </Button>
                    {
                        errorCode !== '' &&
                        <Alert severity={errorCode === "Student Has been Created Successfully" ? "success" : "error"}>{errorCode}</Alert>
                    }
                </div>
            </div>
            <Dialog
                open={dialogOpen}
                keepMounted
                onClose={
                    () => {
                        setDialogOpen(false)
                    }
                }
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className='red_button'>Assesment Test Warning !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        By clicking at start button, test will start automatilcy and you have to finalize in within 20 min, Are you sure you want to Start the Test Now ?!
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={
                        () => {
                            setDialogOpen(false)
                        }
                    }
                        color="primary">
                        <div className="green_button">Cancle</div>
                    </Button>
                    <Button onClick={
                        async () => {
                            //console.log('createdId',createdId)
                            window.location.replace(`/test/start-test?id=${createdId}&show_result=${showResult}`)
                            setDialogOpen(false)
                        }
                    } color="primary">
                        <div className="red_button">Start</div>
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )

}
export default ContactFormTest;