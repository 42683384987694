import { Button, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { getApiRequest, postApiRequest } from '../../../services/apiRequest';
import HttpsIcon from '@material-ui/icons/Https';
import './style.scss'

function LogIn({ history, ...props }) {

    const [setting, setSetting] = useState({})
    const [isloading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loginResul, setLoginResult] = useState('')
    useEffect(async () => {
        const setting = await getApiRequest("/settings")
        if (setting?.data) {
            setSetting(setting.data)
            setIsLoading(true)
        }
    }, [])

    const [classed, setClassed] = useState('')
    const [classed2, setClassed2] = useState('')
    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setClassed('')
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);
    const wrapperRef2 = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setClassed2('')
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef2]);
    return (
        isloading &&
        <div className="login_container flex_row_center_center ">
            <div className="login_content">
                <div className="login_image"><img src={setting.logo}></img>
                </div>
                <div className="company_name">{setting.name_en}</div>
                <div className="field_container">
                    <TextField
                        ref={wrapperRef}
                        id="input-with-icon-textfield"
                        onFocus={() => {
                            setClassed('test')
                            setClassed2('')
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle className={classed} />
                                </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                        className={`textfield`}
                        placeholder={'user Name'}
                        onChange={
                            (e) => {
                                setEmail(e.target.value)
                                //console.log('email', e.target.value)
                            }
                        }
                    />
                </div>
                <div className="field_container">
                    <TextField
                        type="password"
                        ref={wrapperRef2}
                        id="input-with-icon-textfield"
                        onFocus={() => {
                            setClassed('')
                            setClassed2('test')
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HttpsIcon className={classed2} />
                                </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                        className={`textfield`}
                        placeholder={'Password'}
                        onChange={
                            (e) => {
                                setPassword(e.target.value)
                                //console.log('pass', e.target.value)
                            }
                        }
                    />
                </div>
                <div
                    className="login_btn"
                    onClick={
                        async () => {
                            const login = await postApiRequest("/admins/login", {
                                email,
                                password
                            })
                            if (login?.data?.status) {
                                setLoginResult('Correct')
                                localStorage.setItem("token", login.data.token)
                                localStorage.setItem("status", login.data.status)
                                localStorage.setItem("admin", JSON.stringify(login.data.admin))
                                history.push('/admin')
                            } else {
                                setLoginResult(login?.data?.message)
                            }
                            //console.log('login', login?.data)
                        }
                    }
                >
                    Login
                </div>
                <div>
                { loginResul && "Result:"}   {loginResul}
                </div>
                <div className="employees-login"
                onClick={
                    () => {
                        history.push('/admin/employee-login')
                    }
                }
                >
                    Employees LogIn
                </div>
            </div>
        </div>
    )
}
export default LogIn;