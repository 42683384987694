import './style.scss';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useEffect, useState } from 'react';
import { Slide, SwipeableDrawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LeftDrawer from '../leftDrawer';
import ArticleDrawer from '../articleDrawer';
let first_time = true
function LeftSidePanel({ menus, courses }) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isUseEffect, setIsUseEffect] = useState(false)
    const [lang, setLang] = useState('ar')
    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'ar')
        }
        let language = localStorage.getItem('lang') || 'ar'
        setLang(language)
    }, [])
    useEffect(() => {
        first_time = true
        setIsUseEffect(true)
    }, [])

    return (
        isUseEffect && <>
            <div className="left_side_container">
                <div className="menu_container flex_col_center_center" onClick={() => setIsDrawerOpen(true)}>
                    <MenuIcon className="menu_icon_burger" />
                    <div className="menu_lable">{lang === 'en' ? "MENU" : "القوائم"}</div>
                </div>
                <div className="news_container flex_col_center_center" onClick={() => {
                    setIsOpen(!isOpen)
                    first_time = false
                }
                }>
                    <div className="text_rotate">
                      {lang === 'en' ? "Recent Article" : "اخر الااخبار"}  
                     <div className={`arrow_icon ${isOpen ? 'rotate' : first_time ? '' : 'rotate2'}`}><ArrowDownwardIcon /></div>
                        <div className="line"></div>
                    </div>
                </div>
                <div className="eplore_container flex_col_center_center" onClick={() => {
                    document.getElementById('explore')?.scrollIntoView({
                        behavior: 'smooth'
                    });
                }}>
                   {lang === 'en' ? "Courses" : "الدورات"} 
                <div className="arrow"><ArrowDownwardIcon /></div>
                </div>
            </div>
            <LeftDrawer type="pc" menus={menus} courses={courses} isDrawerOpen={isDrawerOpen} onCloseCB={() => setIsDrawerOpen(false)} />
            <ArticleDrawer
                type="pc"
                menus={menus}
                courses={courses}
                isOpen={isOpen}
                first_time={first_time}
                onCloseCB={() => setIsOpen(false)} />
        </>
    );
}

export default LeftSidePanel;
