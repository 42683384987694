import './style.scss'
function FormLayout({ name, ...props }) {
    return (

        <div className="admin_settings_container">
            <div className="header_container">
                <div className="settings_text border_left_blue">{name}</div>
            </div>
            <div className="body">
                {props.children}
            </div>
        </div>
    )
}
export default FormLayout;