import { useEffect, useState } from 'react';
import { getApiRequest, putApiRequest } from '../../../services/apiRequest';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import './style.scss'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { getQuery } from '../../../services/utility';

import ReactCountdownClock from 'react-countdown-clock'
let limit = 55
function StartTestNow() {
    const [questions, setQuestions] = useState([])
    const [isLoading, setIsloading] = useState(false)
    const [arrayIndex, setArrayIndex] = useState(0)
    const [choosenAnswer, setChoosenAnswer] = useState(-1)
    const [result, setResult] = useState(0)
    const [forms, setForms] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    let id = getQuery('id')
    let showResult = getQuery('show_result') || 'false'

    useEffect(
        async () => {
            const question = await getApiRequest('/question', { limit })
            if (question?.data?.docs) {
                setQuestions(question?.data?.docs)
                setIsloading(true)
            }
            if (!id) {
                window.location.replace('/test/new-test')
            }
        }, []
    )

    return (
        isLoading &&
        <>
            <div className="test_form_container">
                <div className="book_container">

                    <div className="book_now">
                        {arrayIndex + 1} - {questions[arrayIndex].question}
                    </div>
                    <div className='test_clock'>
                        <ReactCountdownClock seconds={15 * 60}
                            color="rgb(232, 244, 253)"
                            alpha={0.9}
                            size={120}
                            onComplete={async () => {
                                const put = await putApiRequest('/students/' + id, {
                                    student: {
                                        results: { mark: result, forms }
                                    }
                                })
                                if (put.success) {
                                    setDialogOpen(true)
                                }
                            }} />
                    </div>
                </div>
                <div className={`filed ${choosenAnswer === 0 ? `active` : ''}`}
                    onClick={
                        () => {
                            setChoosenAnswer(0)
                        }
                    }
                >
                    A-  {questions[arrayIndex].answers[0]}
                </div>
                <div className={`filed ${choosenAnswer === 1 ? `active` : ''}`}
                    onClick={
                        () => {
                            setChoosenAnswer(1)
                        }
                    }
                >
                    B-  {questions[arrayIndex].answers[1]}
                </div>
                <div className={`filed ${choosenAnswer === 2 ? `active` : ''}`}
                    onClick={
                        () => {
                            setChoosenAnswer(2)
                        }
                    }
                >
                    C-   {questions[arrayIndex].answers[2]}
                </div>
                <div className={`filed ${choosenAnswer === 3 ? `active` : ''}`}
                    onClick={
                        () => {
                            setChoosenAnswer(3)
                        }
                    }
                >
                    D-   {questions[arrayIndex].answers[3]}
                </div>
                <div className="next_button">


                    {arrayIndex + 1 !== limit ?
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                                setArrayIndex(arrayIndex + 1)
                            }}
                            style={{
                                marginLeft: 10,
                                marginRight: 10
                            }}
                        >
                            SKIP
                    </Button> : ''}

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            if (choosenAnswer !== -1) {
                                let final_result = result, isTrue = false
                                if (questions[arrayIndex].correctAnswer === questions[arrayIndex].answers[choosenAnswer]) {
                                    final_result++
                                    isTrue = true
                                }
                                setResult(final_result)
                                let tmp = [].concat(forms)
                                tmp.push({
                                    question: questions[arrayIndex].question,
                                    answer: questions[arrayIndex].answers[choosenAnswer],
                                    correctAnswer: questions[arrayIndex].correctAnswer,
                                    isTrue: isTrue
                                })
                                setForms(tmp)
                                if (arrayIndex + 1 > limit - 1) {
                                    ///// finish exam
                                    const put = await putApiRequest('/students/' + id, {
                                        student: {
                                            results: { mark: final_result, forms: tmp }
                                        }
                                    })
                                    if (put.success) {
                                        setDialogOpen(true)
                                    }
                                } else {
                                    setChoosenAnswer(-1)
                                    setArrayIndex(arrayIndex + 1)
                                }
                            }
                        }}
                    >
                        {arrayIndex + 1 !== limit ? 'Next - التالي' : 'Finish - انهاء'}
                    </Button>

                </div>
                <div style={{ width: '100%' }}>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            const put = await putApiRequest('/students/' + id, {
                                student: {
                                    results: { mark: result, forms }
                                }
                            })
                            if (put.success) {
                                setDialogOpen(true)
                            }
                        }}
                        style={{ float: 'right', marginRight: 150 }}
                    >
                        انهاء الاختبار
                    </Button>
                </div>
                <div className="hint">
                    <Alert severity="info"><strong>في حال الضغط على زر التالي لا يمكنك العودة الى السؤال احالي</strong></Alert>
                </div>
                <div className="hint">
                    <Alert severity="info"><strong>in case you pressed at next button you will not be able to change the answer</strong></Alert>
                </div>
            </div>
            <Dialog
                open={dialogOpen}
                keepMounted
                onClose={
                    () => {

                    }
                }
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className='red_button'>Done !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        thank you for your valuable time, please contact To Be English representer to get your Result.
                        Have a nice day .....
                        شكرًا لك على وقتك الثمين ، يرجى التواصل مع ممثل المعهد للحصول على النتيجة 
          </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={
                        async () => {
                            //console.log('createdId',createdId)
                            if (showResult === 'true') {
                                window.location.replace(`/admin/report?id=${id}&resultId=last`)
                            } else {
                                window.location.replace('/')
                            }
                            // setDialogOpen(false)
                        }
                    } color="primary">
                        <div className="red_button">Bye</div>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};
export default StartTestNow;