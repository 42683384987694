import { Link } from 'react-router-dom';
import './style.scss';
function Pagination(
    { page,
        totalpages,
        maxNumberOfPages
    }
) {
    let leftArray = [], rightArray = [], finalArray = []
    let page2 = parseInt(page), totalpages2 = parseInt(totalpages)
    let i = 1, j = 1
    while (i <= maxNumberOfPages) {
        if (page2 == 1) {
            break;
        } else {
            if (page2 - i < 1) {
                break;
            } else {
                if (i == maxNumberOfPages) {
                    leftArray.push(1)
                } else {
                    leftArray.push(page2 - i)
                }
                i++
            }
        }
    }
    leftArray.reverse();
    while (j <= maxNumberOfPages) {
        if (page2 == totalpages2) {
            break;
        } else {
            if (j == maxNumberOfPages) {
                rightArray.push(totalpages2)
                break;
            } else {
                if (page2 + j > totalpages2) {
                    break;
                } else {
                    rightArray.push(page2 + j)
                    if (page2 + j === totalpages2) {
                        break;
                    }
                    j++
                }
            }
        }
    }
    finalArray = [...leftArray, page2, ...rightArray]
    return (
        <>
            <div className="flex_row_center_center Pagination_container">
                <Link to={`?page=${page - 1}`} className={`page ${parseInt(page) === (1) && 'hidden'}`}>
                    <div>back</div>
                </Link>
                {
                    finalArray.map((i) => {
                        let pageDiv = <Link to={`?page=${i}`} className={`page ${parseInt(page) === (i) && 'active'}`}>
                            <div>{i}</div>
                        </Link>
                        switch (i) {
                            case 1:
                                return (<>
                                    {pageDiv}
                                    {totalpages > (maxNumberOfPages * 2) && <div className={`page`}>
                                        <div>...</div>
                                    </div>
                                    }
                                </>)
                            case totalpages:
                                return (<>
                                    {
                                        totalpages > (maxNumberOfPages * 2) && <div className={`page`}>
                                            <div>...</div>
                                        </div>
                                    }
                                    {pageDiv}
                                </>)
                            default:
                                return (pageDiv)
                        }
                    })
                }
                <Link to={`?page=${parseInt(page) + 1}`} className={`page ${parseInt(page.toString()) === (totalpages) && 'hidden'}`}>
                    <div>next</div>
                </Link>
            </div>
        </>
    )
}
export default Pagination;