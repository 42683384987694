import './style.scss'
import Alert from '@material-ui/lab/Alert';
import { Button, MenuItem, Select, TextField } from '@material-ui/core';
import { postApiRequest } from '../../services/apiRequest';
import { useEffect, useState } from 'react';
function ContactForm(
    {
        course_id,
        title,
        requestType,
        submitText
    }
) {
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [message, setMessage] = useState('')
    const [errorCode, setErrorCode] = useState('')
    const [lang, setLang] = useState('ar')
    const [emirate, setEmirate] = useState('Dubai')
    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'ar')
        }
        let language = localStorage.getItem('lang') || 'ar'
        setLang(language)
    }, [])
    return (
        <div className="book_form_container">
            <div className="book_container">
                <div className="book_now"> {title} </div>
            </div>
            <div className="filed">
                <TextField id="full_name" label={lang === 'en' ? "Name" : "الاسم"} variant="outlined" className="textfield"
                    onChange={
                        (event) => {
                            setFullName(event.target.value)
                        }
                    } />
            </div>

            <div className="filed">
                <TextField id="email" label={lang === 'en' ? "E-mail" : "البريد الالكروني"} variant="outlined" className="textfield"
                    onChange={
                        (event) => {
                            setEmail(event.target.value)
                        }
                    }
                />
            </div>
            {
                lang !== 'en' ?
                    <div className="filed">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className="textfield"
                            defaultValue="Dubai"
                            value={emirate}
                            onChange={
                                (e) => {
                                    setEmirate(e.target.value)
                                    //console.log("e.target.value", e.target.value)
                                }
                            }
                        >
                            <MenuItem value={'abu Dhabi'}>ابو ظبي</MenuItem>
                            <MenuItem value={'Al Ain'}>العين</MenuItem>
                            <MenuItem value={'Dubai'}>دبي</MenuItem>
                            <MenuItem value={'Sharjah'}>الشارقة</MenuItem>
                            <MenuItem value={'Ajman'}>عجمان</MenuItem>
                            <MenuItem value={'RAK'}>راس الخيمة</MenuItem>
                            <MenuItem value={'Fujairah'}>الفجيرة</MenuItem>
                        </Select>
                    </div>
                    :
                    <div className="filed">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className="textfield"
                            defaultValue="Dubai"
                            value={emirate}
                            onChange={
                                (e) => {
                                    setEmirate(e.target.value)
                                    //console.log("e.target.value", e.target.value)
                                }
                            }
                        >
                            <MenuItem value={'Abu Dhabi'}>Abu dhabi</MenuItem>
                            <MenuItem value={'Al ain'}>Al ain</MenuItem>
                            <MenuItem value={'Dubai'}>Dubai</MenuItem>
                            <MenuItem value={'Sharjah'}>sharjah</MenuItem>
                            <MenuItem value={'Ajman'}>ajman</MenuItem>
                            <MenuItem value={'RAK'}>RAK</MenuItem>
                            <MenuItem value={'Fujairah'}>Fujairah</MenuItem>

                        </Select>
                    </div>
            }

            <div className="filed">
                <TextField
                    id="phone_number"
                    label={lang === 'en' ? "Phone Number" : "رقم الهاتف"}
                    variant="outlined"
                    className="textfield"
                    value={phoneNumber}
                    onChange={
                        (event) => {
                            setPhoneNumber(event.target.value)
                        }
                    }
                />
            </div>
            <div className="filed">
                <TextField id="message" label={lang === 'en' ? "Your Massage" : "نص الرسالة"} multiline className="textfield"
                    rows={10} variant="outlined"
                    onChange={
                        (event) => {
                            setMessage(event.target.value)
                        }
                    }
                />
            </div>

            <div className="filed">
                <Button variant="contained" color="primary" className="textfield"
                    onClick={
                        async () => {
                            if (fullName && email && phoneNumber && message && emirate && phoneNumber.length >= 10 && !!parseInt(phoneNumber)) {
                                //console.log('requestType',requestType)

                                const contact = await postApiRequest('/contact', {
                                    full_name: fullName,
                                    email,
                                    phone_number: phoneNumber,
                                    message,
                                    type: requestType,
                                    emirate,
                                    sub_course: requestType === 'book' ? course_id : null
                                })
                                //console.log('email', email)
                                setErrorCode("Sent!")
                            } else {
                                setErrorCode(phoneNumber.length < 10 || !!!parseInt(phoneNumber) ? "Phone Number should be more than 10 digits" : 'Please fill all the above fields !')
                            }
                        }
                    }
                >
                    {submitText}
                </Button>
                {
                    errorCode !== '' &&
                    <Alert severity={errorCode === "Sent!" ? "success" : "error"}>{errorCode}</Alert>
                }
            </div>
        </div>
    )

}
export default ContactForm;