import { useEffect, useState } from 'react';
import { getApiRequest } from '../../services/apiRequest';
import './style.scss';
import ReactHtmlParser from 'react-html-parser';
import SlideShowAny from '../slideShowAny/slideShow';
import { Modal } from '@material-ui/core';
import ReactPlayer from 'react-player';
function PeopleOpinionComp() {
    const [peopleOpinions, setPeopleOpinions] = useState({})
    const [isLoading, setIsloading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [peopleOpinionVid, SetPeopleOpinionVid] = useState('')
    const [lang, setLang] = useState('en')
    useEffect(async () => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'en')
        }
        let language = localStorage.getItem('lang') || 'en'
        setLang(language)
        const peopleopinion = await getApiRequest('/people-opinion')
        if (peopleopinion?.data?.docs) {
            let array = []
            for (const item of peopleopinion?.data?.docs) {
                array.push(<div key={item._id} class="entry"
                    onClick={
                        () => {
                            setModalOpen(true)
                            SetPeopleOpinionVid(item.video_link)
                        }
                    }
                >
                    <div className="name">{item[`name_${language}`]}</div>
                    <img src={item.image_link} alt="" />
                    <div className="quote">{ReactHtmlParser(item[`description_${language}`])}</div>
                </div>)
            }
            setPeopleOpinions(array)
            setIsloading(true)
        }
    }, [])
    return (
        isLoading &&
        <div>
            <h1>{lang === 'en' ? "Our amazing people" : "طلابنا الاعزاء"}</h1>
            <div className="container">
                <SlideShowAny sliders={[...peopleOpinions,...peopleOpinions]} />

            </div>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="vid_player">
                    <ReactPlayer
                        url={peopleOpinionVid}
                        width="100%"
                        height="100%"
                        playing={false}
                        controls
                        volume={1}
                    />

                </div>
            </Modal>

        </div>
    )

};
export default PeopleOpinionComp;