import './style.scss';
import { useEffect, useState } from 'react';
import { Slide, SwipeableDrawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
const LeftDrawer = ({
    menus,
    isDrawerOpen,
    onCloseCB,
    type,
    courses = []
}) => {

    const closeDrawer = () => {
        onCloseCB()
    }
    const [lang, setLang] = useState('ar')
    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'ar')
        }
        let language = localStorage.getItem('lang') || 'ar'
        setLang(language)
    }, [])
    return (
        <SwipeableDrawer
            anchor={'left'}
            open={isDrawerOpen}
            onClose={() => closeDrawer()}
        >
            <Slide direction="Left" in={isDrawerOpen} mountOnEnter unmountOnExit>
                <div className={type === 'mobile' ? 'swipeable_drawer_mobile_container' : 'swipeable_drawer_container'}>
                    <div className="header_container">
                        <div className="close_drawer" onClick={() => closeDrawer()}>
                            <CloseIcon className="close_icon" />
                        {lang === 'en' ? "Menu" : "القوائم"}
                    </div>
                        <div className="right_drawer">

                        </div>
                    </div>
                    <div className="menues_container">
                        {menus.map((menu) => (
                            <div key={menu._id}>
                                <Link className="link" to={menu.link}>{menu[`name_${lang}`]}</Link>
                            </div>
                        ))}
                        <div>
                           
                        <Link className="link" to="/test/new-test" target="_blank"> {lang === 'en' ? "To Be English Assesment Test" : "الاختبار التقييمي لمعهد تو بي انجلش"}</Link>
                        </div>
                        {courses.map((course) => {
                            return (
                                <div key={course._id}>
                                    <Link className="link" to={`/course?id=${course._id}`} onClick={() => closeDrawer()}>{course[`name_${lang}`]}</Link>
                                </div>
                            )
                        })
                        }
                    </div>

                </div>
            </Slide>
        </SwipeableDrawer>
    );
}

export default LeftDrawer;
