import { useEffect, useState } from 'react';
import ContactFormTest from '../../../component/ContactFormTest';
import { getApiRequest } from '../../../services/apiRequest';
import './style.scss'
function NewAssessmentTest() {
    const [settings, setSettings] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    useEffect(
        async () => {
            if (!localStorage.getItem('lang')) {
                localStorage.setItem('lang', 'ar')
            }
            const setting = await getApiRequest('/settings')
            if (setting?.data) {
                setSettings(setting.data)
                setIsLoading(true)
            }
        }, [])
    return (
        isLoading &&
        <>
            <div className="new-assessment-test-Container">
                <div className="assessment-form-container">
                    <img src={settings.logo}></img>
                    <ContactFormTest title={settings.name_en + " Assesment Test"} submitText="Start your Test Now" />
                </div>

            </div>
        </>
    )
};
export default NewAssessmentTest;