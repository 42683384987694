import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Modal, Snackbar, useScrollTrigger } from '@material-ui/core';
import { useEffect, useState } from 'react';
import FormLayout from '../../../component/admin/formLayout';
import { deleteApiRequest, getApiRequest, postApiRequest, putApiRequest } from '../../../services/apiRequest';
import EmployeeLayout from '../employeeLayout';
import './style.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InputBaseWithLable from '../../../component/admin/inputBaseWithLabel';
import { Alert } from '@material-ui/lab';
import { getQuery } from '../../../services/utility';
import Pagination from '../../../component/pagination';
import WYSIWYGCKEditor from '../../../component/admin/DraftEditor';
import Upload from '../../../component/admin/upload';
function EmployeeArticles({ history }) {
    const [isLoading, setIsloading] = useState(false)
    const [nameEn, setNameEn] = useState('')
    const [nameAr, setNameAr] = useState('')
    const [discEn, setDiscEn] = useState('')
    const [discAr, setDiscAr] = useState('')
    const [imgLink, setImgLink] = useState('')
    const [vidLink, setVidLink] = useState('')
    const [password, setPassword] = useState('')
    const [fullName, setFullName] = useState('')
    const [articels, setArticels] = useState([])
    const [articleId, setArticleId] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [snakBarStatus, setSnakBarStatus] = useState(false)
    const [successStatus, setSuccessStatus] = useState('')
    const [alertText, setAlertText] = useState('')
    const [addNewMenuModalFlag, setAddNewMenuModalFlag] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    let page = getQuery('page') || 1
    const [totalpages, setTotalPages] = useState(1)
    useEffect(async () => {
        const articles = await getApiRequest('/articles', { page })
        if (articles?.data) {
            setArticels(articles.data.docs)
            setIsloading(true)
            setTotalPages(articles.data.totalPage)
        }
    }, [page])

    return (
        isLoading &&
        <EmployeeLayout history={history}>
            <FormLayout name="Articles(News)">
                <div className="menus_table_container" >
                    <div className="maenus_header_container">
                        <div className="item_header">English Name</div>
                        <div className="item_header">Arabic Name</div>
                        <div className="item_header">Action </div>
                        {
                            // name_en,
                            // name_ar,
                            // description_en,
                            // description_ar,
                            // image_link,
                            // video_link
                        }
                    </div>
                    {
                        articels && articels.map(
                            (articel, index) => {
                                return (
                                    <div key={articels._id} className={`item_container ${index % 2 !== 0 && 'background_second'}`}>
                                        <div className="item">{articel.name_en}</div>
                                        <div className="item">{articel.name_ar}</div>
                                        <div className="item flex_row">
                                            <DeleteIcon
                                                className='cursor_pointer delete_icon'
                                                onClick={
                                                    () => {
                                                        setArticleId(articel._id)
                                                        setDialogOpen(true)
                                                    }
                                                }
                                            />
                                            <EditIcon className='cursor_pointer edit_icon'
                                                onClick={
                                                    () => {
                                                        setArticleId(articel._id)
                                                        setNameEn(articel.name_en)
                                                        setNameAr(articel.name_ar)
                                                        setDiscEn(articel.description_en)
                                                        setDiscAr(articel.description_ar)
                                                        setVidLink(articel.video_link)
                                                        setImgLink(articel.image_link)
                                                        setCurrentIndex(index)
                                                        setModalOpen(true)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>

                <Pagination page={page} totalpages={totalpages} maxNumberOfPages={4} />
                <div className="new_menu_button">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                // let tmpArray = [].concat(phoneNumbers)
                                // tmpArray.push('')
                                // setPhoneNumbers(tmpArray)
                                setArticleId('')
                                setNameEn('')
                                setNameAr('')
                                setDiscEn('')
                                setDiscAr('')
                                setVidLink('')
                                setImgLink('')
                                setAddNewMenuModalFlag(true)
                            }
                        }

                    >
                        Add New Article
                </Button>

                </div>
            </FormLayout>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="modal_container special_modal">
                    <InputBaseWithLable
                        label="English Name"
                        placeholder="English Name"
                        value={nameEn}
                        onChange={(e) => {
                            setNameEn(e.target.value)
                        }}
                    />

                    <InputBaseWithLable
                        label="Arabic Name"
                        placeholder="Arabic Name"
                        value={nameAr}
                        onChange={(e) => {
                            setNameAr(e.target.value)
                        }}
                    />
                    <div className="partition">
                        Arabic Description
                     </div>
                    <WYSIWYGCKEditor
                        value={discAr}
                        cb={
                            (value) => {
                                setDiscAr(value)
                            }
                        }
                    />
                    <div className="partition">
                        English Description
                     </div>
                    <WYSIWYGCKEditor
                        value={discEn}
                        cb={
                            (value) => {
                                setDiscEn(value)
                            }
                        }
                    />
                    {/* <InputBaseWithLable
                        label="English Description"
                        placeholder="English Description"
                        value={discEn}
                        onChange={(e) => {
                            setDiscEn(e.target.value)
                        }}
                    />
                    <InputBaseWithLable
                        label="Arabic Description"
                        placeholder="Arabic Description"
                        value={discAr}
                        onChange={(e) => {
                            setDiscAr(e.target.value)
                        }}
                    /> */}
                    <div className="partition">
                        Upload Image
                    </div>
                    <div className="upload_button">
                        <Upload
                            cb={(x) => {
                                setImgLink(x)
                                //console.log(x)

                            }}
                        />
                    </div>
                    {/* <InputBaseWithLable
                        label="Image Link"
                        placeholder="Image Link"
                        value={imgLink}
                        onChange={(e) => {
                            setImgLink(e.target.value)
                        }}
                    /> */}
                    <div className="partition">
                        Upload video
                    </div>
                    <div className="upload_button">
                        <Upload
                            cb={(x) => {
                                setVidLink(x)
                                //console.log(x)

                            }}
                        />
                    </div>
                    {/* <InputBaseWithLable
                        label="video Link"
                        placeholder="video Link"
                        value={vidLink}
                        onChange={(e) => {
                            setVidLink(e.target.value)
                        }}
                    /> */}
                    <div className="update_now_button">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                async () => {
                                    const updatArticle = await putApiRequest(`/articles/${articleId}`, {
                                        article: {
                                            name_en: nameEn,
                                            name_ar: nameAr,
                                            description_en: discEn,
                                            description_ar: discAr,
                                            image_link: imgLink,
                                            video_link: vidLink

                                        }
                                    })
                                    //console.log('updatARticle', updatArticle)
                                    if (updatArticle.success) {
                                        // First way to refresh the data after updat from modal
                                        let temp = [].concat(articels)
                                        let index = temp.findIndex((articel) => articel._id === articleId)
                                        temp[index].name_en = nameEn
                                        temp[index].name_ar = nameAr
                                        temp[index].description_en = discEn
                                        temp[index].description_ar = discAr
                                        temp[index].image_link = imgLink
                                        temp[index].video_link = vidLink
                                        setArticels(temp)
                                        setModalOpen(false)
                                        setSnakBarStatus(true)
                                        setSuccessStatus('success')
                                        setAlertText('Article Updated Successfuly !')
                                    } else {
                                        setSnakBarStatus(true)
                                        setSuccessStatus('error')
                                        setAlertText(`${updatArticle.message}`)
                                    }
                                }
                            }
                        >
                            Update NOW !
                </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={addNewMenuModalFlag}
                onClose={() => setAddNewMenuModalFlag(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="modal_container special_modal">
                    <div className='flex_col_center_center'> Add New Article</div>
                    <InputBaseWithLable
                        label="English Name"
                        placeholder="English Name"
                        value={nameEn}
                        onChange={(e) => {
                            setNameEn(e.target.value)
                        }}
                    />
                    <InputBaseWithLable
                        label="Arabic Name"
                        placeholder="Arabic Name"
                        value={nameAr}
                        onChange={(e) => {
                            setNameAr(e.target.value)
                        }}
                    />
                    <div className="partition">
                        Arabic Description
                     </div>
                    <WYSIWYGCKEditor
                        value={discAr}
                        cb={
                            (value) => {
                                setDiscAr(value)
                            }
                        }
                    />
                    <div className="partition">
                        English Description
                     </div>
                    <WYSIWYGCKEditor
                        value={discEn}
                        cb={
                            (value) => {
                                setDiscEn(value)
                            }
                        }
                    />
                    {/* <InputBaseWithLable
                        label="Arabic Name"
                        placeholder="Arabic Name"
                        value={nameAr}
                        onChange={(e) => {
                            setNameAr(e.target.value)
                        }}
                    />
                    <InputBaseWithLable
                        label="English Description"
                        placeholder="English Description"
                        value={discEn}
                        onChange={(e) => {
                            setDiscEn(e.target.value)
                        }}
                    /> */}
                    {/* <InputBaseWithLable
                        label="Arabic Description"
                        placeholder="Arabic Description"
                        value={discAr}
                        onChange={(e) => {
                            setDiscAr(e.target.value)
                        }}
                    /> */}
                      <div className="partition">
                        Upload Image
                    </div>
                    <div className="upload_button">
                        <Upload
                            cb={(x) => {
                                setImgLink(x)
                                //console.log(x)

                            }}
                        />
                    </div>
                    <div className="partition">
                        Upload video
                    </div>
                    <div className="upload_button">
                        <Upload
                            cb={(x) => {
                                setVidLink(x)
                                //console.log(x)

                            }}
                        />
                    </div>
                    {/* <InputBaseWithLable
                        label="Image Link"
                        placeholder="Image Link"
                        value={imgLink}
                        onChange={(e) => {
                            setImgLink(e.target.value)
                        }}
                    />
                    <InputBaseWithLable
                        label="video Link"
                        placeholder="video Link"
                        value={vidLink}
                        onChange={(e) => {
                            setVidLink(e.target.value)
                        }}
                    /> */}

                    <div className="update_now_button">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                async () => {
                                    const newArticle = await postApiRequest('/articles', {
                                        name_en: nameEn,
                                        name_ar: nameAr,
                                        description_en: discEn,
                                        description_ar: discAr,
                                        image_link: imgLink,
                                        video_link: vidLink
                                    })
                                    if (newArticle.success) {
                                        // First way to refresh the data after updat from modal
                                        let temp = [].concat(articels)
                                        temp.unshift(newArticle.data.article)
                                        setArticels(temp)
                                        setAddNewMenuModalFlag(false)
                                        setSnakBarStatus(true)
                                        setSuccessStatus('success')
                                        setAlertText('New Article has been created Successfuly !')
                                    } else {
                                        setSnakBarStatus(true)
                                        setSuccessStatus('error')
                                        setAlertText(`${newArticle.message}`)
                                    }
                                }
                            }
                        >
                            Add NOW !
                </Button>
                    </div>
                </div>
            </Modal>
            <Dialog
                open={dialogOpen}
                keepMounted
                onClose={
                    () => {
                        setDialogOpen(false)
                    }
                }
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className='red_button'>Delete !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this Article ?!
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={
                        () => {
                            setDialogOpen(false)
                        }
                    }
                        color="primary">
                        <div className="green_button">Cancle</div>
                    </Button>
                    <Button onClick={
                        async () => {
                            const deleteArticle = await deleteApiRequest(`/articles/${articleId}`)
                            if (deleteArticle.success) {
                                let tmp = [].concat(articels)
                                let temp2 = articels.filter((article) => article._id !== articleId)
                                setArticels(temp2)
                                setSnakBarStatus(true)
                                setSuccessStatus('success')
                                setAlertText('Article deleted Successfuly !')
                            } else {
                                setSnakBarStatus(true)
                                setSuccessStatus('error')
                                setAlertText('Ooops....Something went Wrong !')
                            }
                            setDialogOpen(false)
                        }
                    } color="primary">
                        <div className="red_button">Delete</div>
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snakBarStatus} autoHideDuration={5000}
                onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                }
            >
                <Alert onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                } severity={successStatus}>
                    {alertText}
                </Alert>
            </Snackbar>
        </EmployeeLayout>
    )
}
export default EmployeeArticles;