import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@material-ui/core';
import { useEffect, useState } from 'react';
import FormLayout from '../../../component/admin/formLayout';
import { getApiRequest } from '../../../services/apiRequest';
import AdminLayout from '../layout';
import './style.scss'
import InfoIcon from '@material-ui/icons/Info';
import { getQuery } from '../../../services/utility';
import Pagination from '../../../component/pagination';
import InputBaseWithLable from '../../../component/admin/inputBaseWithLabel';
import PrintIcon from '@material-ui/icons/Print';
import { useDebounce } from '../../../services/useDebounce';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
function AssessmentTest({ history }) {
    const [stds, setStds] = useState([])
    const [isLoading, setIsloading] = useState(false)
    const [totalpages, setTotalpages] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [results, setResults] = useState([])
    const [phoneNumber, setPhoneNumber] = useState('')
    const [stdId, setStdId] = useState('')
    const [forms, setForms] = useState([])

    let page = getQuery('page') || 1
    useEffect(
        async () => {
            const student = await getApiRequest('/students', { page })
            if (student?.data?.docs) {
                setStds(student.data.docs)
                setTotalpages(student.data.totalPage)
                setIsloading(true)
            }
        }, [page])

    const [search, setSearch] = useState('')
    const debouncedSearchTerm = useDebounce(search, 500);
    // Effect for API call
    useEffect(
        async () => {
            const student = await getApiRequest('/students', { page, search })
            if (student?.data?.docs) {
                setStds(student.data.docs)
                setTotalpages(student.data.totalPage)
                setIsloading(true)
            }
        }, [debouncedSearchTerm]);
    return (
        isLoading &&
        <AdminLayout history={history}>
            <FormLayout name="Assessment Test">
                <div className="menus_table_container" >
                    <InputBaseWithLable
                        label=""
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                    />
                    <div className="maenus_header_container">
                        <div className="item_header">Student Name</div>
                        <div className="item_header">Studnet Email </div>
                        <div className="item_header">Student Mobile Number</div>
                        <div className="item_header">Student Emirate</div>
                        <div className="item_header">Action </div>
                    </div>
                    {
                        stds && stds.map(
                            (std, index) => {
                                return (
                                    <>
                                        <div key={std._id} className={`item_container ${index % 2 !== 0 && 'background_second'}`}>
                                            <div className="item">{(std.name).substring(0, 30)}</div>
                                            <div className="item">{(std.email).substring(0, 30)}</div>
                                            <div className="item">{(std.phone_number).substring(0, 30)}</div>
                                            <div className="item">{(std.emarah)}</div>
                                            <div className="item flex_row">
                                                <InfoIcon
                                                    className='cursor_pointer edit_icon'
                                                    onClick={
                                                        () => {
                                                            // setQestionId(question._id)
                                                            setStdId(std._id)
                                                            setName(std.name)
                                                            setEmail(std.email)
                                                            setResults(std.results)
                                                            setPhoneNumber(std.phone_number)
                                                            setModalOpen(true)
                                                            setForms([])
                                                        }
                                                    }
                                                />
                                            </div>

                                        </div>
                                    </>
                                )
                            }
                        )
                    }
                </div>
                <Pagination page={page} totalpages={totalpages} maxNumberOfPages={4} />
                <div className="new_test_button">


                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                window.open('/test/new-test?show_result=true', "_blank")
                            }
                        }
                    >
                        Start A New Test NOW
                </Button>
                </div>
            </FormLayout>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="modal_container special_modal">
                    Student Name : {name},,,,,,,
                Student Email : {email},,,,,,,
                Student Mobile Number : {phoneNumber}

                    <div className="menus_table_container" >
                        <div className="maenus_header_container">
                            <div className="item_header">Date</div>
                            <div className="item_header">Mark</div>
                            <div className="item_header">Print </div>
                        </div>
                        {
                            results && results.map(
                                (result, index2) => {
                                    return (
                                        <>
                                            <div key={result._id} className={`item_container ${index2 % 2 !== 0 && 'background_second'}`}>
                                                <div className="item">{result.date}</div>
                                                <div className="item">{!result.mark ? '0' : result.mark + '/55'}</div>
                                                <div className="item flex_row">
                                                    <div className='cursor_pointer edit_icon'
                                                        onClick={
                                                            () => {
                                                                window.open(`/admin/report?id=${stdId}&resultId=${index2}`, "_blank")
                                                            }
                                                        }
                                                    >
                                                        <PrintIcon />
                                                    </div>
                                                    <div className='cursor_pointer edit_icon'
                                                        onClick={
                                                            () => {
                                                                setForms(result.forms)
                                                                window.open(`/admin/error-student?forms=${JSON.stringify(result.forms)}`, "_blank")
                                                            }

                                                        }
                                                    >
                                                        <QuestionAnswerIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        }
                    </div>

                    {
                        forms.length > 0 ?
                            <div className="menus_table_container" >
                                <div className="maenus_header_container">
                                    <div className="item_header">Question</div>
                                    <div className="item_header">Student Answer</div>
                                    <div className="item_header">Correct Answer </div>
                                </div>


                                {
                                    forms.filter((item) => item.isTrue === false).map(
                                        (form, index3) => {
                                            return (
                                                <>
                                                    <div key={index3} className={`item_container ${index3 % 2 !== 0 && 'background_second'}`}>
                                                        <div className="item">  {form.question} </div>
                                                        <div className="item">{form.answer}</div>
                                                        <div className="item flex_row">{form.correctAnswer}</div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    )

                                }
                            </div>
                            : null
                    }
                </div>
            </Modal>
        </AdminLayout>
    )
};
export default AssessmentTest;