import './App.scss';
import './style.css';
import './aos.css';
import './home_style.scss';
import { useEffect, useState } from 'react';
import { getApiRequest } from './services/apiRequest';
import SlideShow from './component/slideShow/slideShow';
import Layout from './component/layout';
import CoursesView from './component/coursesView';
import ContactForm from './component/ContactForm';
import { Grid, Modal } from '@material-ui/core';
import { getQuery } from './services/utility';
import { Link } from 'react-router-dom';
import AOS from 'aos'
import PeopleOpinionComp from './component/peopleOpinionComp';
import ReactPlayer from 'react-player';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
const defaultProps = {
  center: {
    lat: 59.95,
    lng: 30.33
  },
  zoom: 11
};
function Home() {
  const [isLoading, setIsLoading] = useState(false)
  const [sliders, setSliders] = useState([])
  const [courses, setCourses] = useState([])
  const [settings, setSettings] = useState({})
  const [totalpages, setTotalPages] = useState(1)
  const [articles, setArticles] = useState([])
  const [lang, setLang] = useState('ar')
  const [peopleOpinions, setPeopleOpinions] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [peopleOpinionVid, SetPeopleOpinionVid] = useState('')
  let page = getQuery('page') || 1
  useEffect(async () => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar')
    }
    let language = localStorage.getItem('lang') || 'ar'
    setLang(language)

    const slider = await getApiRequest("/sliders", { location: 'HOME' })
    if (slider?.data) {
      setSliders(slider.data)
      const course = await getApiRequest("/courses")
      if (course?.data) {
        setCourses(course.data);
      }
      const articles = await getApiRequest(`/articles`, { page })
      if (articles?.data) {
        setTotalPages(articles.data.totalPage)
        setArticles(articles.data.docs)

      }
      const peopleopinion = await getApiRequest('/people-opinion')
      if (peopleopinion?.data?.docs) {
        setPeopleOpinions(peopleopinion.data.docs)
        setIsLoading(true)
      }
    }
    AOS.init({
      duration: 2000
    })

  }, [])

  return (
    isLoading &&
    <>
      <Layout settingCB={(data) => {
        setSettings(data)
      }}>
        <div className='slide_show_container'>
          <SlideShow sliders={sliders} />
        </div>
        <div id='explore'
          data-aos="fade-up">
          <CoursesView courses={courses} link="course?id=" />
        </div>
        <Grid container >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <div className="slideshow_3d" data-aos="zoom-out-up" data-aos-duration="3000">
              <div className="content">
                <div className="slider-content">
                  {
                    articles.map((article) => {
                      return (
                        <div key={article._id} className="shadow" >
                          <Link to={`article?id=${article._id}`}>
                            <span className='slide_content_container'>
                              <img src={article.image_link} />
                              <span className='slider_title'>{article[`name_${lang}`]}</span>
                            </span>
                          </Link>

                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h1>{lang === 'en' ? "Our amazing people" : "طلابنا الاعزاء"}</h1>
            <div className="slideshow_3d" data-aos="zoom-out-up" data-aos-duration="3000">
              <div className="content">
                <div className="slider-content2">
                  {
                    peopleOpinions.map((item) => {
                      return (
                        <div key={item._id} className="shadow" >
                          <div
                            onClick={
                              () => {
                                setModalOpen(true)
                                SetPeopleOpinionVid(item.video_link)
                              }
                            }
                          >
                            <span className='slide_content_container'>
                              <img src={item.image_link} />
                              <span className='slider_title'>{item[`name_${lang}`]}</span>
                            </span>
                          </div>

                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div className="vid_player flex_col">
                  <div className="close_video" onClick={() => setModalOpen(false)}><CancelPresentationIcon /><div>  Close - اغلاق</div></div>

                  <ReactPlayer
                    url={peopleOpinionVid}
                    width="100%"
                    height="100%"
                    playing={false}
                    controls
                    volume={1}
                  />

                </div>
              </Modal>
            </div>
          </Grid>
        </Grid>
        <div className='contact_us'
          data-aos="fade-up">
          <Grid container >
            <Grid item xs={12} sm={12} md={8} lg={8} >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30749.751544574654!2d55.3309034!3d25.2445764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d9b479207e7%3A0xf07ff0bd49f2df17!2sTo%20Be%20English%20Institute!5e1!3m2!1sen!2s!4v1620152120744!5m2!1sen!2s"
                width="100%" height="450" allowfullscreen="" loading="lazy"
                style={{ border: 0 }}></iframe>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} >
              <ContactForm title={lang === 'en' ? "Contact Us!" : "تواصل معنا"} submitText={lang === 'en' ? "Send Now" : "ارسل الان"} requestType='contact-us' />
            </Grid>
          </Grid>
        </div>
      </Layout>
    </>
  );
}

export default Home;
