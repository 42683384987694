import "./admin_test.scss";
import { Button, TextField } from "@material-ui/core";
import { useState } from "react";
import { postApiRequest } from "../services/apiRequest";
function AdminCourse() {
    const [nameEn, setNameEn] = useState('')
    const [nameAr, setNameAr] = useState('')
    const [descriptionAr, setDescriptionAr] = useState('')
    const [descriptionEn, setDescriptionEn] = useState('')
    const [order, setOrder] = useState(0)
    const [coverImage, setCoverImage] = useState('')
    return (
        <>
            <div className="admin_test_container">
                <TextField
                    id="standard-basic"
                    label="name_ar"
                    onChange={(event) => {
                        setNameAr(event.target.value)
                    }}
                />
                <TextField
                    id="standard-basic"
                    label="name_en"
                    onChange={(event) => {
                        setNameEn(event.target.value)
                    }
                    }
                />
                <TextField id="standard-basic"
                    label="description_ar"
                    multiline rowsMax={4}
                    rows={4}
                    onChange={(event) => {
                        setDescriptionAr(event.target.value)
                    }}
                />
                <TextField
                    id="standard-basic"
                    label="description_en"
                    multiline
                    rowsMax={4}
                    rows={4}
                    onChange={(event) => {
                        setDescriptionEn(event.target.value)
                    }}
                />
                <TextField
                    id="standard-basic"
                    label="order"
                    type="number"
                    onChange={(event) => {
                        setOrder(event.target.value)
                    }}
                />
                <TextField
                    id="standard-basic"
                    label="cover_image"
                    onChange={(event) => {
                        setCoverImage(event.target.value)
                    }}
                />
                <Button variant="contained"
                    onClick={async () => {
                        const courses = await postApiRequest("/courses", {
                            name_ar: nameAr,
                            name_en: nameEn,
                            description_ar: descriptionAr,
                            description_en: descriptionEn,
                            order,
                            cover_image: coverImage
                        })
                    }}
                >Add Course</Button>
            </div>
        </>
    )
}
export default AdminCourse;