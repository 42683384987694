import { InputBase } from '@material-ui/core';
import { useEffect, useState } from 'react';
import './style.scss'
function InputBaseWithLable({
    className = "gray_text_field",
    errorMessage = '',
    ...props
}) {
    return (
        <>
            <div className="partition">
                {props.label}
                <span>{errorMessage.length > 0 && errorMessage}</span>
            </div>
            <InputBase
                inputProps={{
                    disableUnderline: true
                }}
                className={`${className} ${errorMessage.length > 0 && 'error'}`}
                {...props}
            />
        </>
    )
}
export default InputBaseWithLable;
