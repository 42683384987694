
import { useLocation as UseLocation } from "react-router-dom";
export const getQuery = (string) => {
    let query = new URLSearchParams(UseLocation().search);
    return query.get(string)
}
export const RedirectToAdmin = ({history}) => {
    return history.push('/admin/login')
}
export const RedirectToEmployee = ({history}) => {
    return history.push('/admin/employee-login')
}