import './style.scss';
import Layout from "../../component/layout";
import { useEffect, useState } from 'react';
import { getApiRequest } from '../../services/apiRequest';
import { Hidden } from '@material-ui/core';
import { getQuery } from '../../services/utility';
import Paragraph from '../../component/paragraph';
import CoursesView from '../../component/coursesView';
import { Link } from 'react-router-dom';
import Pagination from '../../component/pagination';
import AOS from 'aos';
function Articles() {
    const [articles, setArticles] = useState([])
    const [isloading, setIsLoading] = useState(false)
    const [totalpages, setTotalPages] = useState(1)
    let page = getQuery('page') || 1
    const [lang, setLang] = useState('ar')
  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar')
    }
    let language = localStorage.getItem('lang') || 'ar'
    setLang(language)
  }, [])
    useEffect(async () => {
        const articles = await getApiRequest(`/articles`, { page })
        if (articles?.data) {
            setTotalPages(articles.data.totalPage)
            setArticles(articles.data.docs)
        }
        setIsLoading(true)
        AOS.init({
            duration: 2000
        })
    }, [page])
    return (
        isloading &&
        <>
            <Layout>
                <Hidden only={["xs"]} >
                    <div className="article_main_container">
                        <div className="article_company_name"> {lang === 'en' ? "Articles" : "اخر المقالات والاخبار"} </div>
                    </div>
                </Hidden>
                <div data-aos="flip-left"> <CoursesView courses={articles} link="article?id=" cover_image={'image_link'} /></div>
                <Pagination page={page} totalpages={totalpages} maxNumberOfPages={4} />
            </Layout>
        </>
    );
}
export default Articles;