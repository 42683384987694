import { Button } from '@material-ui/core';
import { useEffect, useState, setState } from 'react';
import FormLayout from '../../../component/admin/formLayout';
import {  getApiRequest } from '../../../services/apiRequest';
import AdminLayout from '../layout';
import './style.scss';
import SubSliders from './sub-sliders';
function Sliders({ history }) {
    const [isLoading, setIsloading] = useState(false)
    const [slider, setslider] = useState([])
    const [SliderName, setSliderName] = useState('')
    const [isSubCourse, setIsSubCourse] = useState(false)
    const [sliderLocation , setSliderLocation] = useState('')
    useEffect(async () => {
        const slider = await getApiRequest('/sliders/gourp-by-location')
        if (slider?.data) {
            setslider(slider.data)
            //console.log(slider.data)
            setIsloading(true)
        }
    }, [])
    return (
        isLoading &&
        <AdminLayout history={history}>
            <FormLayout name="Sliders">
                <div className="menus_table_container" >
                    <div className="maenus_header_container">
                        <div className="item_header">Location</div>
                        <div className="item_header">Action </div>
                        {
                            //  name_ar,
                            //  name_en,
                            //  description_ar,
                            //  description_en,
                            //  order,
                            //  cover_image
                        }
                    </div>
                    {
                        slider && slider.map(
                            (slider, index) => {
                                return (
                                    <div  key={slider.location} className={`item_container ${index % 2 !== 0 && 'background_second'}`}>
                                        <div className="item"><div className="display_table">{slider.location}</div></div>
                                        <div className="item flex_row">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="is_sub_course_button"
                                                onClick={
                                                    () => {
                                                        setIsSubCourse(true)
                                                        setSliderLocation(slider.location)
                                                        setSliderName(slider.location)
                                                        // //console.log(course._id)
                                                    }
                                                }
                                            >
                                                Sub Course
                                            </Button>

                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
                <SubSliders isSubslider={isSubCourse} mainSliderLocation={sliderLocation} sliderName={sliderLocation} history={history} />
            </FormLayout>
        </AdminLayout>
    )
}
export default Sliders;