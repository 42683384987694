import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Snackbar, useScrollTrigger } from '@material-ui/core';
import { useEffect, useState, setState } from 'react';
import FormLayout from '../../../component/admin/formLayout';
import { deleteApiRequest, getApiRequest, postApiRequest, putApiRequest } from '../../../services/apiRequest';
import AdminLayout from '../layout';
import './style.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InputBaseWithLable from '../../../component/admin/inputBaseWithLabel';
import { Alert } from '@material-ui/lab';
import FileBase64 from 'react-file-base64';
import Upload from '../../../component/admin/upload';
import SubCourse from './sub-courses';
import WYSIWYGCKEditor from '../../../component/admin/DraftEditor';
function AdminCourses({ history }) {

    const [isLoading, setIsloading] = useState(false)
    const [courses, setCourses] = useState([])
    const [courseId, setCourseId] = useState('')
    const [courseId2, setCourseId2] = useState('')

    const [dialogOpen, setDialogOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [snakBarStatus, setSnakBarStatus] = useState(false)
    const [successStatus, setSuccessStatus] = useState('')
    const [alertText, setAlertText] = useState('')
    const [addNewMenuModalFlag, setAddNewMenuModalFlag] = useState(false)
    const [nameEn, setNameEn] = useState('')
    const [nameAr, setNameAr] = useState('')
    const [discEn, setDiscEn] = useState('')
    const [discAr, setDiscAr] = useState('')
    const [imgLink, setImgLink] = useState('')
    const [order, setOrder] = useState('')
    const [errors, setErrors] = useState({})
    const [courseName, setCourseName] = useState('')
    const [isSubCourse, setIsSubCourse] = useState(false)
    useEffect(async () => {
        const courses = await getApiRequest('/courses')
        if (courses?.data) {
            setCourses(courses.data)
            setIsloading(true)
        }
    }, [])
    return (
        isLoading &&
        <AdminLayout history={history}>
            <FormLayout name="Courses">
                <div className="menus_table_container" >
                    <div className="maenus_header_container">
                        <div className="item_header">English Name</div>
                        <div className="item_header">Arabic Name</div>
                        <div className="item_header">Action </div>
                        {
                            //  name_ar,
                            //  name_en,
                            //  description_ar,
                            //  description_en,
                            //  order,
                            //  cover_image
                        }
                    </div>
                    {
                        courses && courses.map(
                            (course, index) => {
                                return (
                                    <div key={course._id} className={`item_container ${index % 2 !== 0 && 'background_second'}`}>
                                        <div className="item"><div className="display_table">{course.name_en.substr(0, 20)}...</div></div>
                                        <div className="item"><div className="display_table">...{course.name_ar.substr(0, 20)} </div></div>
                                        <div className="item flex_row">
                                            <DeleteIcon
                                                className='cursor_pointer delete_icon'
                                                onClick={
                                                    () => {
                                                        setCourseId(course._id)
                                                        setDialogOpen(true)
                                                    }
                                                }
                                            />
                                            <EditIcon className='cursor_pointer edit_icon'
                                                onClick={
                                                    () => {
                                                        setCourseId(course._id)
                                                        setNameEn(course.name_en)
                                                        setNameAr(course.name_ar)
                                                        setDiscEn(course.description_en)
                                                        setDiscAr(course.description_ar)
                                                        setOrder(course.order)
                                                        setImgLink(course.cover_image)
                                                        setCurrentIndex(index)
                                                        setModalOpen(true)
                                                        setErrors({})
                                                    }
                                                }
                                            />

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="is_sub_course_button"
                                                onClick={
                                                    () => {
                                                        setIsSubCourse(true)
                                                        setCourseId2(course._id)
                                                        setCourseName(course.name_en)
                                                        // //console.log(course._id)
                                                    }
                                                }
                                            >
                                                Sub Course
                                            </Button>

                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
                <div className="new_menu_button">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                // let tmpArray = [].concat(phoneNumbers)
                                // tmpArray.push('')
                                // setPhoneNumbers(tmpArray)
                                setCourseId('')
                                setNameEn('')
                                setNameAr('')
                                setDiscEn('')
                                setDiscAr('')
                                setOrder('')
                                setImgLink('')
                                setAddNewMenuModalFlag(true)
                            }
                        }

                    >
                        Add New Course
                </Button>
                </div>
                <SubCourse isSubCourse={isSubCourse} mainCourseId={courseId2} CourseName={courseName} />
            </FormLayout>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="modal_container special_modal">
                    <InputBaseWithLable
                        label="English Name"
                        placeholder="English Name"
                        value={nameEn}
                        onChange={(e) => {
                            setNameEn(e.target.value)
                        }}
                        errorMessage={errors[`course.name_en`]}
                    />

                    <InputBaseWithLable
                        label="Arabic Name"
                        placeholder="Arabic Name"
                        value={nameAr}
                        onChange={(e) => {
                            setNameAr(e.target.value)
                        }}
                        errorMessage={errors[`course.name_ar`]}
                    />
                     <div className="partition">
                     Arabic Description
                     </div>
                    <WYSIWYGCKEditor 
                    value = {discAr}
                    cb={
                        (value)=> {
                            setDiscAr(value)
                        }
                    }
                    />
                    <div className="partition">
                     English Description
                     </div>
                    <WYSIWYGCKEditor 
                    value = {discEn}
                    cb={
                        (value)=> {
                            setDiscEn(value)
                        }
                    }
                    />
                    <div className="partition">
                        Upload Image
                    <span className="image_err">
                            {
                                errors[`course.cover_image`]?.length > 0 && errors[`course.cover_image`]
                            }
                        </span>
                    </div>
                    <div className="upload_button">
                        <Upload
                            cb={(x) => {
                                setImgLink(x)
                                //console.log(x)

                            }}
                        />
                    </div>
                    <InputBaseWithLable
                        label="Order"
                        placeholder="Order"
                        value={order}
                        onChange={(e) => {
                            setOrder(e.target.value)
                        }}
                        errorMessage={errors[`course.order`]}
                    />
                    {
                        //      <InputBaseWithLable
                        //      label="Cover Image"
                        //      placeholder="Cover Image"
                        //      value={imgLink}
                        //      onChange={(e) => {
                        //          setImgLink(e.target.value)
                        //      }}
                        //  />
                    }


                    <div className="update_now_button">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                async () => {
                                    const updateCourse = await putApiRequest(`/courses/${courseId}`, {
                                        course: {
                                            name_ar: nameAr,
                                            name_en: nameEn,
                                            description_ar: discAr,
                                            description_en: discEn,
                                            order,
                                            cover_image: imgLink

                                        }
                                    })
                                    //console.log('nameAr', nameAr)
                                    //console.log('nameEn', nameEn)
                                    //console.log('discAr', discAr)
                                    //console.log('discEn', discEn)
                                    //console.log('order', order)
                                    //console.log('imgLink', imgLink)
                                    if (updateCourse.success) {
                                        // First way to refresh the data after updat from modal
                                        let temp = [].concat(courses)
                                        let index = temp.findIndex((course) => course._id === courseId)
                                        temp[index].name_ar = nameAr
                                        temp[index].name_en = nameEn
                                        temp[index].description_ar = discAr
                                        temp[index].description_en = discEn
                                        temp[index].order = order
                                        temp[index].cover_image = imgLink.toString()
                                        setCourses(temp)
                                        setModalOpen(false)
                                        setSnakBarStatus(true)
                                        setSuccessStatus('success')
                                        setAlertText('Course Updated Successfuly !')
                                        //console.log(updateCourse)
                                    } else {
                                        setSnakBarStatus(true)
                                        setSuccessStatus('error')
                                        if (updateCourse.errors) {
                                            let errors = {}
                                            updateCourse.errors.map((err) => {
                                                errors[err.param] = err.msg
                                            })
                                            setErrors(errors)
                                            setAlertText(`check above fields`)
                                        } else {
                                            setAlertText(`${updateCourse.message}`)
                                        }
                                    }
                                }
                            }
                        >
                            Update NOW !
                </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={addNewMenuModalFlag}
                onClose={() => setAddNewMenuModalFlag(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="modal_container">
                    <div className='flex_col_center_center'> Add New Course</div>
                    <InputBaseWithLable
                        label="English Name"
                        placeholder="English Name"
                        value={nameEn}
                        onChange={(e) => {
                            setNameEn(e.target.value)
                        }}
                        errorMessage={errors[`name_en`]}
                    />

                    <InputBaseWithLable
                        label="Arabic Name"
                        placeholder="Arabic Name"
                        value={nameAr}
                        onChange={(e) => {
                            setNameAr(e.target.value)
                        }}
                        errorMessage={errors[`name_ar`]}
                    />
                    <InputBaseWithLable
                        label="Arabic Description"
                        placeholder="Arabic Description"
                        value={discAr}
                        onChange={(e) => {
                            setDiscAr(e.target.value)
                        }}
                        errorMessage={errors[`description_ar`]}

                    />
                    <InputBaseWithLable
                        label="English Description"
                        placeholder="English Description"
                        value={discEn}
                        onChange={(e) => {
                            setDiscEn(e.target.value)
                        }}
                        errorMessage={errors[`description_en`]}
                    />

                    <div className="partition">
                        Upload Image
                    <span className="image_err">
                            {
                                errors[`cover_image`]?.length > 0 && errors[`cover_image`]
                            }
                        </span>
                    </div>
                    <div className="upload_button">
                        <Upload
                            cb={(x) => {
                                setImgLink(x)
                            }}
                        />


                    </div>
                    <InputBaseWithLable
                        label="Order"
                        placeholder="Order"
                        value={order}
                        onChange={(e) => {
                            setOrder(e.target.value)
                        }}
                        errorMessage={errors[`order`]}
                    />

                    <div className="update_now_button">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                async () => {
                                    const newCourse = await postApiRequest('/courses', {
                                        name_ar: nameAr,
                                        name_en: nameEn,
                                        description_ar: discAr,
                                        description_en: discEn,
                                        order,
                                        cover_image: imgLink

                                    })
                                    if (newCourse.success) {
                                        // First way to refresh the data after updat from modal
                                        let temp = [].concat(courses)
                                        temp.unshift(newCourse.data.course)
                                        setCourses(temp)
                                        setAddNewMenuModalFlag(false)
                                        setSnakBarStatus(true)
                                        setSuccessStatus('success')
                                        setAlertText('New Course has been created Successfuly !')
                                    } else {
                                        setSnakBarStatus(true)
                                        setSuccessStatus('error')
                                        if (newCourse.errors) {
                                            let errors = {}
                                            newCourse.errors.map((err) => {
                                                errors[err.param] = err.msg
                                            })
                                            //console.log(errors)
                                            setErrors(errors)
                                            setAlertText(`check above fields`)
                                        } else {
                                            setAlertText(`${newCourse.message}`)
                                        }
                                    }
                                }
                            }
                        >
                            Add NOW !
                </Button>
                    </div>
                </div>
            </Modal>
            <Dialog
                open={dialogOpen}
                keepMounted
                onClose={
                    () => {
                        setDialogOpen(false)
                    }
                }
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className='red_button'>Delete !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this Course ?!
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={
                        () => {
                            setDialogOpen(false)
                        }
                    }
                        color="primary">
                        <div className="green_button">Cancle</div>
                    </Button>
                    <Button onClick={
                        async () => {
                            const deleteCourse = await deleteApiRequest(`/courses/${courseId}`)
                            if (deleteCourse.success) {
                                let tmp = [].concat(courses)
                                let temp2 = courses.filter((course) => course._id !== courseId)
                                setCourses(temp2)
                                setSnakBarStatus(true)
                                setSuccessStatus('success')
                                setAlertText('Course deleted Successfuly !')
                            } else {
                                setSnakBarStatus(true)
                                setSuccessStatus('error')
                                setAlertText('Ooops....Something went Wrong !')
                            }
                            setDialogOpen(false)
                        }
                    } color="primary">
                        <div className="red_button">Delete</div>
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snakBarStatus} autoHideDuration={5000}
                onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                }
            >
                <Alert onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                } severity={successStatus}>
                    {alertText}
                </Alert>
            </Snackbar>

        </AdminLayout>
    )
}
export default AdminCourses;