import { useEffect, useState } from 'react'
import FormLayout from '../../../component/admin/formLayout'
import { getApiRequest, putApiRequest } from '../../../services/apiRequest'
import EmployeeLayout from '../employeeLayout'
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import './style.scss'
import { Button, FormControl, InputLabel, MenuItem, Modal, Select, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getQuery } from '../../../services/utility';
import Pagination from '../../../component/pagination';
function MessageCenterEmployee({ history }) {
    const [isLoading, setIsLoading] = useState(true)
    const [contacts, setContacts] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [contact, setContact] = useState({})
    const [employeeId, setEmployeeId] = useState('')
    const [employees2, setEmployees2] = useState([])
    const [snakBarStatus, setSnakBarStatus] = useState(false)
    const [successStatus, setSuccessStatus] = useState('')
    const [alertText, setAlertText] = useState('')
    let contactId = getQuery('id')
    let page = getQuery('page') || 1
    const [totalpages, setTotalPages] = useState(1)
    useEffect(async () => {
        const contact = await getApiRequest(`/contact/by-employee`,{page})
        if (contact?.data) {
            setContacts(contact.data.docs)
            setTotalPages(contact.data.totalPage)
            //console.log("contact.data", contact.data)
            setIsLoading(true)
        }
        const employees = await getApiRequest('/employees/getEmployees')
        if (employees?.data) {
            setEmployees2(employees?.data)
        }
    }, [page])

    useEffect(async () => {
        if (contactId?.length > 0) {
            const employees = await getApiRequest('/contact/' + contactId)
            if (employees?.data) {
                onClickShowContact(employees?.data.contact)
                setModalOpen(true)
            }
        }
    }, [contactId])

    const onClickShowContact = (contact) => {
        setContact(contact)
        setModalOpen(true)
        if (contact?.employee) {
            setEmployeeId(contact.employee._id)
        } else {
            setEmployeeId('')
        }
        history.push('?id=' + contact._id)
    }
    return (
        <>
            <EmployeeLayout history={history}>
                <FormLayout name="Message Center">
                    <div className="menus_table_container" >
                        <div className="maenus_header_container">
                            <div className="item_header">Type</div>
                            <div className="item_header">Full Name</div>
                            <div className="item_header">Phone No. </div>
                            <div className="item_header">Empl Ref </div>
                            <div className="item_header">Action </div>
                        </div>
                        {
                            contacts && contacts.map(
                                (contact, index) => {
                                    return (
                                        <div key={contact._id} className={`item_container ${index % 2 !== 0 && 'background_second'}`}>
                                            <div className="item"><div className="">{contact.type} </div></div>
                                            <div className="item"><div className="display_table">{contact.full_name} </div></div>
                                            <div className="item"><div className="display_table">{contact.phone_number} </div></div>
                                            <div className="item"><div className="">{contact.employee ? contact.employee.full_name : "No Employee"} </div></div>
                                            <div className="item flex_row">
                                                <InsertCommentIcon
                                                    className="comment_icon"
                                                    onClick={
                                                        () => {
                                                            onClickShowContact(contact)
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                    <Pagination page={page} totalpages={totalpages} maxNumberOfPages={4} />
                </FormLayout>
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal_container ">
                        <div className="message_container">
                            <div><div className="text">Type :</div> {contact.type}</div>
                            <div><div className="text">From :</div> {contact.email}</div>
                            <div><div className="text">Sender Name :</div> {contact.full_name}</div>
                            <div><div className="text">Phone Number :</div> {contact.phone_number}</div>
                            <div><div className="text">Course Name :</div> {contact.sub_course ? contact.sub_course.name_en : "Not Set"}</div>
                            <div><div className="text">Message:</div></div>
                            <div className="message"></div>
                            {contact.message}

                            <div>
                                <div className="text">
                                    Assigned to  :
                        </div>
                                <div>
                               {JSON.parse(window.localStorage.getItem('Employee')).full_name} 
                                   
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </Modal>
                <Snackbar open={snakBarStatus} autoHideDuration={5000}
                    onClose={
                        () => {
                            setSnakBarStatus(false)
                        }
                    }
                >
                    <Alert onClose={
                        () => {
                            setSnakBarStatus(false)
                        }
                    } severity={successStatus}>
                        {alertText}
                    </Alert>
                </Snackbar>
            </EmployeeLayout>
        </>
    )
};
export default MessageCenterEmployee;