import './style.scss';
import Layout from "../../component/layout";
import { useEffect, useState } from 'react';
import { getApiRequest } from '../../services/apiRequest';
import { Hidden } from '@material-ui/core';
import { getQuery } from '../../services/utility';
import Paragraph from '../../component/paragraph';
import ReactPlayer from 'react-player';
function Article() {
    const [article, setArticle] = useState({})
    const [isloading, setIsLoading] = useState(false)
    const article_id = getQuery('id')
    const [lang, setLang] = useState('ar')
    const [playing, setPlaying] = useState(true)
    const [mute, setMute] = useState(true)
   // const videoRef = useRef(null)
    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'ar')
        }
        let language = localStorage.getItem('lang') || 'ar'
        setLang(language)
    }, [])
    useEffect(async () => {
        const article = await getApiRequest(`/articles/${article_id}`)
        if (article?.data)
            setArticle(article.data)
        setIsLoading(true)
        setPlaying(true)
    }, [article_id])
    const dateFormate = (dateTime) => {
        let finalFormate = new Date(dateTime).toDateString();
        let srting = finalFormate.split(' ')
        srting[0] = `${srting[0]},`
        finalFormate = srting.join(' ')
        return finalFormate;
    }

    return (
        isloading &&
        <>
            <Layout>
                <Hidden only={["xs"]} >
                    <div className="article_main_container" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                        <div className="article_company_name"> {article[`name_${lang}`]} </div>
                    </div>
                </Hidden>


                <div className="sub_article_container">
                    <div className="image">
                        <img src={article.image_link}></img>

                    </div>
                    <div className="created_at">
                        {
                            dateFormate(article.createdAt)
                        }
                    </div>
                    <div style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                        <Paragraph title={article[`name_${lang}`]} body={article[`description_${lang}`]} />
                    </div>
                    {
                        article.video_link.length > 10 &&   
                        <div className="image">
                        <ReactPlayer
                           // ref={videoRef}
                            url={article.video_link}
                            width="100%"
                            height="100%"
                            playing={playing}
                            controls
                            volume={1}
                            muted={mute}
                            // onEnded={
                            //     () => {
                            //         setIndex(index + 1)
                            //     }
                            // }
                            onSeek={() => {
                                //   setMute(false)
                            }}
                            onClick={() => {
                                setMute(false)
                            }}
                        />
                    </div>
                    }
                  
                </div>
            </Layout>
        </>
    );
}
export default Article;