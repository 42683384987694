import './style.scss'
import x from './2BeLOGO.png'
import { useEffect, useState } from 'react'
import { getQuery } from '../../../../services/utility'
import { getApiRequest } from '../../../../services/apiRequest'
import ReactHtmlParser from 'react-html-parser';
import 'dayjs/locale/es' // load on demand
var dayjs = require('dayjs')

function Report() {
    const [isLoading, setIsLoading] = useState('')
    const [std, setStd] = useState({})
    const [stdResult, setStdResult] = useState({})
    const [EnglishLevel, setEnglishLevel] = useState('')
    const [CEFR, setCEFR] = useState('')
    const [IELTS, setIELTS] = useState('')
    const [TOEFLPPT, setTOEFLPPT] = useState('')
    const [TOEFLIBT, setTOEFLIBT] = useState('')
    const [EMSAT, setEMSAT] = useState('')
    const [correctAnswers, setCorrectAnswers] = useState('')
    const [incorrectAnswers, setincorrectAnswers] = useState('')
    const [description, setDescription] = useState('')
    const [testDate, setTestDate] = useState('')
    let id = getQuery('id')
    let resultId = getQuery('resultId') || 0
    useEffect(
        async () => {
            const student = await getApiRequest(`/students/${id}`)
            let mark = 0
            if (student?.data) {
                setStd(student.data)
                if (resultId === 'last') {
                    setStdResult(student.data.results[student.data.results.length - 1])
                    mark = student.data.results[student.data.results.length - 1].mark
                } else {
                    setStdResult(student.data.results[resultId])
                    mark = student.data.results[resultId].mark

                }

                setTestDate(dayjs(student?.data?.createdAt).format('YYYY-MM-DD, hh-mm a'))
                setIsLoading(true)
            }

            // let mark = 30
            if (mark >= 0 && mark <= 5) {
                setEnglishLevel('Level 1')
                setCEFR('A1  Absolute beginner')
                setIELTS('0.0-1.0')
                setTOEFLPPT('0-310')
                setTOEFLIBT('0.0-8.0')
                setEMSAT('0-100')
                setDescription(`<br>• Unable to read and write <br>
                • Cannot interact or produce simple sentences <br>
                • May know some basic words like name , place , address… <br>
                • Is unable to listen and interact with others `)
            }
            if (mark > 5 && mark <= 10) {
                setEnglishLevel('Level 2')
                setCEFR('A1 beginner')
                setIELTS('1.0 -1.5')
                setTOEFLPPT('310-343')
                setTOEFLIBT('9.0-18')
                setEMSAT('125-300')
                setDescription(`<br>• Can understand and use familiar everyday expressions and very basic phrases aimed at the satisfaction of needs of a concrete type. 
                <br> • Can interact in a simple way provided the other person talks slowly and clearly and is prepared to help`)
            }
            if (mark >= 11 && mark <= 20) {
                setEnglishLevel('Level 3')
                setCEFR('A1/A2 elementary')
                setIELTS('2.0-2.5')
                setTOEFLPPT('347-393')
                setTOEFLIBT('19-29')
                setEMSAT('325-475')
                setDescription(`<br>• Can introduce him/herself and others and can ask and answer questions about personal details such as where he/she lives, people he/she knows and things he/she has.
                <br>• Can understand sentences and frequently used expressions related to areas of most immediate relevance (e.g. very basic personal and family information, shopping, local geography, employment).
                `)
            }
            if (mark >= 21 && mark <= 29) {
                setEnglishLevel('Level 4')
                setCEFR('A2 Pre-intermediate')
                setIELTS('3')
                setTOEFLPPT('397-433')
                setTOEFLIBT('30-40')
                setEMSAT('500-850')
                setDescription(`<br>• Can communicate in simple and routine tasks requiring a simple and direct exchange of information on familiar and routine matters. 
                <br>• Can describe in simple terms aspects of his/her background, immediate environment and matters in areas of immediate need. 
                `)
            }
            if (mark >= 30 && mark <= 37) {
                setEnglishLevel('Level 5')
                setCEFR('B1 intermediate')
                setIELTS('3.5-4.0')
                setTOEFLPPT('437-473')
                setTOEFLIBT('41-52')
                setEMSAT('875-1225')
                setDescription(`<br>• Can understand the main points of clear standard input on familiar matters regularly encountered in work, school, leisure, etc.
                <br>• Can deal with most situations likely to arise whilst travelling in an area where the language is spoken. 
                <br>• Can produce simple connected text on topics, which are familiar, or of personal interest. 
                `)
            }
            if (mark >= 38 && mark <= 44) {
                setEnglishLevel('Level 6')
                setCEFR('B1/B2 intermediate')
                setIELTS('4.5-5.0')
                setTOEFLPPT('477-510')
                setTOEFLIBT('53-64')
                setEMSAT('1250-1425')
                setDescription(`<br>• Can describe experiences and events, dreams, hopes & ambitions and briefly give reasons and explanations for opinions and plans. 
                <br>• Can understand the main ideas of complex text on both concrete and abstract topics, including technical discussions in his/her field of specialization.
                 `)
            }
            if (mark >= 45 && mark <= 50) {
                setEnglishLevel('Level 7')
                setCEFR('B2 upper intermediate')
                setIELTS('5.5-6.0')
                setTOEFLPPT('513-547')
                setTOEFLIBT('65-78')
                setEMSAT('1450-1600')
                setDescription(`<br>• Can interact with a degree of fluency and spontaneity that makes regular interaction with native speakers quite possible without strain for either party.
               <br>• Can produce clear, detailed text on a wide range of subjects and explain a viewpoint on a topical issue giving the advantages and disadvantages of various options.`)
            }
            if (mark >= 51 && mark <= 55) {
                setEnglishLevel('Level 8')
                setCEFR('C1 advanced')
                setIELTS('6.5-7.5')
                setTOEFLPPT('550-587')
                setTOEFLIBT('79-95')
                setEMSAT('1625-1950')
                setDescription(`<br>• Can understand a wide range of demanding, longer texts, and recognize implicit meaning. 
               <br>• Can express him/herself fluently and spontaneously without much obvious searching for expressions. 
               <br>• Can use language flexibly and effectively for social, academic and professional purposes. 
               <br>• Can produce clear, well-structured, detailed text on complex subjects, showing controlled use of organizational patterns, connectors and cohesive devices.`)
            }
            setCorrectAnswers(`${student.data.results[resultId === 'last' ? student.data.results.length - 1 : resultId].mark} / ${student.data.results[resultId === 'last' ? student.data.results.length - 1 : resultId].forms.length}`)
            setincorrectAnswers(`${student.data.results[resultId === 'last' ? student.data.results.length - 1 : resultId].forms.length - student.data.results[resultId === 'last' ? student.data.results.length - 1 : resultId].mark} / ${student.data.results[resultId === 'last' ? student.data.results.length - 1 : resultId].forms.length}`)
        }, [])
    const collectGeneral = () => {
        return (parseInt(correctAnswers) / 55 * 100).toFixed(2)
    }
    return (
        isLoading &&
        <>
            <div className="main_report_container">
                <div className="report_header">
                    To be English institute
                    <img src={x}
                        alt=""
                        className="report_logo"
                    ></img>
                    معهد تو بي انجلش
                </div>
                <div className="orang_c"></div>
                <div className="report_header_string">
                    <div className="report_header_name">
                        Report of Assessment Test
                    </div>

                </div>
                <div className="report_desc">
                    <div>This is to certify that {std.mark} with following credentials :</div>
                    <div>User Name : {std.name}, Email : {std.email}, Phone : {std.phone_number}, </div>
                    <div>Date : {testDate}</div>
                    <div>has succesfully completed the assessment test from To Be English institute.</div>
                </div>
                <div className="report1 bold">
                    Report
                </div>
                <div className="menus_table_container" >
                    <div className="maenus_header_container">
                        <div className="item_header">To Be English Assesment Test</div>
                    </div>
                    <div className={`item_container`}>
                        <div className="item">English Level</div>
                        <div className="item">{EnglishLevel}</div>
                    </div>
                    <div className={`item_container background_second`}>
                        <div className="item">CEFR </div>
                        <div className="item">{CEFR}</div>
                    </div>
                    <div className={`item_container `}>
                        <div className="item">IELTS </div>
                        <div className="item">{IELTS}</div>
                    </div>
                    <div className={`item_container background_second`}>
                        <div className="item">TOEFL PPT</div>
                        <div className="item">{TOEFLPPT}</div>
                    </div>
                    <div className={`item_container `}>
                        <div className="item">TOEFL IBT</div>
                        <div className="item">{TOEFLIBT}</div>
                    </div>
                    <div className={`item_container background_second`}>
                        <div className="item">EMSAT</div>
                        <div className="item">{EMSAT}</div>
                    </div>
                    <div className={`item_container `}>
                        <div className="item">GENERAL AVERAGE</div>
                        <div className="item">{collectGeneral()}%</div>
                    </div>
                    <div className={`item_container background_second`}>
                        <div className="item">You Answered </div>
                        <div className="item">{(stdResult.forms.length)} / 55</div>
                    </div>
                    <div className={`item_container `}>
                        <div className="item">correct answers </div>
                        <div className="item">{correctAnswers}</div>
                    </div>
                    <div className={`item_container background_second`}>
                        <div className="item">incorrect answers </div>
                        <div className="item">{incorrectAnswers}</div>
                    </div>
                    <div className="item_container  p_l_20">
                        Note : {ReactHtmlParser(description)}
                    </div>
                </div>
                <div className="report_desc ">
                    Note : This statement is based on assessment test and valid for only a single applicant. It is valid within the terms and conditions of To Be English. The applicant should hold the report and not distribute it.
                    Support : info@tobeenglish.ae
                </div>

                <div className="report_fotter">
                    <div className="orang_line"> </div>
                    <div className="fotter_body">
                        <div className="fotter_location">Dubai, U.A.E - Sheikh Rashid Road Al Garhoud - Near Al Hallab Restaurant</div>
                        <div className="fotter_line"></div>
                        <div className="fotter_web_site">www.tobeenglish.ae</div>
                        <div className="fotter_line"></div>
                        <div className="fotter_location">Liberty Building - Office 308 <br /> Tel : +97145477784 <br /> Email : Info@tobeenglish.ae</div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default Report;