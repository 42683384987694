import { useEffect, useState } from 'react';
import FormLayout from '../../../component/admin/formLayout';
import { getApiRequest, putApiRequest, postApiRequest,deleteApiRequest } from '../../../services/apiRequest';
import AdminLayout from '../layout';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '../../../component/pagination';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Snackbar } from '@material-ui/core';
import { getQuery } from '../../../services/utility';
import InputBaseWithLable from '../../../component/admin/inputBaseWithLabel';
import WYSIWYGCKEditor from '../../../component/admin/DraftEditor';
import './style.scss';
import { Alert } from '@material-ui/lab';
function Questions({ history }) {
    const [isLoading, setIsLoading] = useState(false)
    const [questions, setQuestions] = useState([])
    const [qestionId, setQestionId] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false)
    const [qestion1, setQestion1] = useState('')
    const [answers1, setAnswers1] = useState([])
    const [correctAnswer1, setCorrectAnswer1] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [totalpages, setTotalPages] = useState(1)
    const [addNewMenuModalFlag, setAddNewMenuModalFlag] = useState(false)
    const [snakBarStatus, setSnakBarStatus] = useState(false)
    const [successStatus, setSuccessStatus] = useState('')
    const [alertText, setAlertText] = useState('')
    let page = getQuery('page') || 1
    useEffect(
        async () => {
            const question = await getApiRequest('/question', { page })
            if (question?.data?.docs) {
                setQuestions(question.data.docs)
                setTotalPages(question.data.totalPage)
                setIsLoading(true)
            }

        }, [page])
    return (
        isLoading &&
        <>
            <AdminLayout history={history}>
                <FormLayout name="Questions">
                    <div className="menus_table_container" >
                        <div className="maenus_header_container">
                            <div className="item_header">Question ?</div>
                            <div className="item_header">Answers </div>
                            <div className="item_header">Correct Answer</div>
                            <div className="item_header">Action </div>
                        </div>
                        {
                            //{question.answers[0]}/{question.answers[1]}/{question.answers[2]}/{question.answers[3]}
                            questions.map(
                                (question, index) => {
                                    return (

                                        <div key={question._id} className={`item_container ${index % 2 !== 0 && 'background_second'} h_70px`}>
                                            <div className="item">{(question.question).substring(0, 30)}</div>
                                            <div className="item">
                                                {
                                                    question.answers.map(
                                                        (answer, index2) => {
                                                            return (
                                                                <>
                                                                    ({answer})
                                                              </>

                                                            )
                                                        }
                                                    )
                                                }
                                            </div>
                                            <div className="item">{question.correctAnswer}</div>
                                            <div className="item flex_row">
                                                <DeleteIcon
                                                    className='cursor_pointer delete_icon'
                                                    onClick={
                                                        () => {
                                                            setQestionId(question._id)
                                                            setDialogOpen(true)
                                                        }
                                                    }
                                                />
                                                <EditIcon className='cursor_pointer edit_icon'
                                                    onClick={
                                                        () => {
                                                            setQestionId(question._id)
                                                            setQestion1(question.question)
                                                            setAnswers1(question.answers)
                                                            setCorrectAnswer1(question.correctAnswer)
                                                            setModalOpen(true)
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                    <Pagination page={page} totalpages={totalpages} maxNumberOfPages={4} />
                    <div className="new_menu_button">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    // let tmpArray = [].concat(phoneNumbers)
                                    // tmpArray.push('')
                                    // setPhoneNumbers(tmpArray)
                                    setQestionId('')
                                    setQestion1('')
                                    setAnswers1('')
                                    setCorrectAnswer1([])
                                    setAddNewMenuModalFlag(true)
                                }
                            }
                        >
                            Add New Question
                </Button>
                    </div>
                </FormLayout>
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal_container special_modal">
                        <InputBaseWithLable
                            label="Qestion Description"
                            placeholder="Qestion Description"
                            value={qestion1}
                            onChange={(e) => {
                                setQestion1(e.target.value)
                            }}
                        />
                        {
                           answers1 && answers1.map(
                                (answer, index) => {
                                    return (
                                        <InputBaseWithLable
                                            key={index}
                                            label={`Answer ${index + 1}`}
                                            placeholder={`Answer ${index + 1}`}
                                            value={answer}
                                            onChange={(e) => {
                                                let tempArry = [].concat(answers1)
                                                tempArry[index] = e.target.value
                                                setAnswers1(tempArry)

                                            }}
                                        />

                                    )
                                }
                            )
                        }
                        <InputBaseWithLable
                            label="Correct Answer"
                            placeholder="Correct Answer"
                            value={correctAnswer1}
                            onChange={(e) => {
                                setCorrectAnswer1(e.target.value)
                            }}
                        />
                        <div className="update_now_button">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    async () => {
                                        const updatQeustion = await putApiRequest(`/question/${qestionId}`, {
                                            test: {
                                                question: qestion1,
                                                answers: answers1,
                                                correctAnswer: correctAnswer1
                                            }
                                        })
                                        //console.log('updatARticle', updatArticle)
                                        if (updatQeustion.success) {
                                            // First way to refresh the data after updat from modal
                                            let temp = [].concat(questions)
                                            let index = temp.findIndex((qestion) => qestion._id === qestionId)
                                            temp[index].question = qestion1
                                            temp[index].answers = answers1
                                            temp[index].correctAnswer = correctAnswer1
                                            setQuestions(temp)
                                            setModalOpen(false)
                                            setSnakBarStatus(true)
                                            setSuccessStatus('success')
                                            setAlertText('Article Updated Successfuly !')
                                            //console.log("NameEn",nameEn) 
                                        } else {
                                            setSnakBarStatus(true)
                                            setSuccessStatus('error')
                                            setAlertText(`${updatQeustion.message}`)
                                        }
                                    }
                                }
                            >
                                Update NOW !
                </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={addNewMenuModalFlag}
                    onClose={() => setAddNewMenuModalFlag(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal_container special_modal">
                        <div className='flex_col_center_center'> Add New Question</div>

                        <InputBaseWithLable
                            label="Qestion Description"
                            placeholder="Qestion Description"
                            value={qestion1}
                            onChange={(e) => {
                                setQestion1(e.target.value)
                            }}
                        />

                        <InputBaseWithLable
                            label="Answer 1"
                            placeholder="Answer 1"
                            value={answers1[0]}
                            onChange={(event) => {
                                let NewArry = [].concat(answers1)
                                NewArry[0] = event.target.value
                                setAnswers1(NewArry) 
                                // Mr Faraj kindly please Note that we can create a new Array (UaseState i mean up their) then fill it each time and down at submit time set it to the orginal Array
                                //setCorrectAnswer1(e.target.value)
                            }}
                        />
                         <InputBaseWithLable
                            label="Answer 2"
                            placeholder="Answer 2"
                            value={answers1[1]}
                            onChange={(event) => {
                                let NewArry = [].concat(answers1)
                                NewArry[1] = event.target.value
                                setAnswers1(NewArry) 
                                //setCorrectAnswer1(e.target.value)
                            }}
                        />
                         <InputBaseWithLable
                            label="Answer 3"
                            placeholder="Answer 3"
                            value={answers1[2]}
                            onChange={(event) => {
                                let NewArry = [].concat(answers1)
                                NewArry[2] = event.target.value
                                setAnswers1(NewArry) 
                                //setCorrectAnswer1(e.target.value)
                            }}
                        />
                          <InputBaseWithLable
                            label="Answer 4"
                            placeholder="Answer 4"
                            value={answers1[3]}
                            onChange={(event) => {
                                let NewArry = [].concat(answers1)
                                NewArry[3] = event.target.value
                                setAnswers1(NewArry) 
                                //setCorrectAnswer1(e.target.value)
                            }}
                        />
                        <InputBaseWithLable
                            label="Correct Answer"
                            placeholder="Correct Answer"
                            value={correctAnswer1}
                            onChange={(e) => {
                                setCorrectAnswer1(e.target.value)
                            }}
                        />

                        <div className="update_now_button">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    async () => {
                                        const newQeustion = await postApiRequest('/question', {
                                            question: qestion1,
                                            answers: answers1,
                                            correctAnswer: correctAnswer1
                                        })
                                        if (newQeustion.success) {
                                            // First way to refresh the data after updat from modal
                                            let temp = [].concat(questions)
                                            temp.unshift(newQeustion.data.test)
                                            setQuestions(temp)
                                            setAddNewMenuModalFlag(false)
                                            setSnakBarStatus(true)
                                            setSuccessStatus('success')
                                            setAlertText('New Question has been created Successfuly !')
                                        } else {
                                            setSnakBarStatus(true)
                                            setSuccessStatus('error')
                                            setAlertText(`${newQeustion.message}`)
                                        }
                                    }
                                }
                            >
                                Add NOW !
                </Button>
                        </div>
                    </div>
                </Modal>
                <Dialog
                open={dialogOpen}
                keepMounted
                onClose={
                    () => {
                        setDialogOpen(false)
                    }
                }
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className='red_button'>Delete !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this Question ?!
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={
                        () => {
                            setDialogOpen(false)
                        }
                    }
                        color="primary">
                        <div className="green_button">Cancle</div>
                    </Button>
                    <Button onClick={
                        async () => {
                            const deleteQuestion = await deleteApiRequest(`/question/${qestionId}`)
                            if (deleteQuestion.success) {
                                let tmp = [].concat(questions)
                                let temp2 = questions.filter((question) => question._id !== qestionId)
                                setQuestions(temp2)
                                setSnakBarStatus(true)
                                setSuccessStatus('success')
                                setAlertText('Question deleted Successfuly !')
                            } else {
                                setSnakBarStatus(true)
                                setSuccessStatus('error')
                                setAlertText('Ooops....Something went Wrong !')
                            }
                            setDialogOpen(false)
                        }
                    } color="primary">
                        <div className="red_button">Delete</div>
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snakBarStatus} autoHideDuration={5000}
                onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                }
            >
                <Alert onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                } severity={successStatus}>
                    {alertText}
                </Alert>
            </Snackbar>
            </AdminLayout>
        </>
    )
};
export default Questions;