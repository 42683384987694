import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Modal, Select, Snackbar, useScrollTrigger } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { deleteApiRequest, getApiRequest, postApiRequest, putApiRequest } from '../../../../services/apiRequest';
import '../style.scss';
import './style.scss';
import '../../../../component/admin/inputBaseWithLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InputBaseWithLable from '../../../../component/admin/inputBaseWithLabel';
import { Alert } from '@material-ui/lab';
import Upload from '../../../../component/admin/upload';
function SubSliders({
    history,
    isSubslider = false,
    mainSliderLocation,
    sliderName
}) {

    const [isLoading, setIsloading] = useState(false)
    const [sliders, setsliders] = useState([])
    const [sliderId, setsliderId] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [snakBarStatus, setSnakBarStatus] = useState(false)
    const [successStatus, setSuccessStatus] = useState('')
    const [alertText, setAlertText] = useState('')
    const [addNewMenuModalFlag, setAddNewMenuModalFlag] = useState(false)
    const [link_en, setlink_en] = useState('')
    const [link_ar, setlink_ar] = useState('')
    const [slide_type, setslide_type] = useState('')
    const [order, setOrder] = useState('')
    const [errors, setErrors] = useState({})

    useEffect(async () => {
        if (mainSliderLocation.length > 0) {
            const sliders = await getApiRequest('/sliders', { location: mainSliderLocation })
            if (sliders?.data) {
                setsliders(sliders.data)
                setIsloading(true)
            }
        }
    }, [mainSliderLocation])
    return (
        isLoading && isSubslider &&
        <>
            <div className="sub_slider_container">
                <div className="settings_text border_left_blue "> Sub Sliders : {sliderName}</div>
                <div className="sub_sliders_container">

                    <div className="menus_table_container" >
                        <div className="maenus_header_container">
                            <div className="item_header">Slider Type</div>
                            <div className="item_header">Order</div>
                            <div className="item_header">Action </div>
                            {
                                // link_ar: { type: String },
                                // link_en: { type: String },
                                // slide_type: { type: String, index: true },
                                // to_go: { type: String },
                                // order: {type: Number},
                            }
                        </div>
                        {
                            sliders && sliders.map(
                                (slider, index) => {
                                    return (
                                        <div key={slider._id} className={`item_container ${index % 2 !== 0 && 'background_second'}`}>
                                            <div className="item"><div className="display_table">{slider.slide_type.substr(0, 20)}</div></div>
                                            <div className="item"><div className="display_table item_order">{slider.order}</div></div>
                                            <div className="item flex_row">
                                                <DeleteIcon
                                                    className='cursor_pointer delete_icon'
                                                    onClick={
                                                        () => {
                                                            setsliderId(slider._id)
                                                            setDialogOpen(true)
                                                        }
                                                    }
                                                />
                                                <EditIcon className='cursor_pointer edit_icon'
                                                    onClick={
                                                        () => {
                                                            setsliderId(slider._id)
                                                            setlink_en(slider.link_en)
                                                            setlink_ar(slider.link_ar)
                                                            setslide_type(slider.slide_type)
                                                            setOrder(slider.order)
                                                            setCurrentIndex(index)
                                                            setModalOpen(true)
                                                            setErrors({})
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                    <div className="new_menu_button">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    // let tmpArray = [].concat(phoneNumbers)
                                    // tmpArray.push('')
                                    // setPhoneNumbers(tmpArray)
                                    setsliderId('')
                                    setlink_en('')
                                    setlink_ar('')
                                    setslide_type('')
                                    setOrder('')
                                    setAddNewMenuModalFlag(true)
                                }
                            }

                        >
                            Add New Sub slider
                </Button>
                    </div>
                </div>
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >

                    <div className="modal_container">
                    <div className="partition">
                            Upload English Image
                    <span className="image_err">
                                {
                                    errors[`slider.link_en`]?.length > 0 && errors[`slider.link_en`]

                                }
                            </span>
                        </div>
                        <div className={`upload_button ${errors[`slider.link_en`]?.length > 0 && errors[`slider.link_en`] ? 'error_border' : ''}`}>
                            <Upload
                                cb={(x) => {
                                    setlink_en(x)
                                    //console.log(x)

                                }}
                            />
                        </div>
                        <div className="partition">
                            Upload Arabic Image
                    <span className="image_err">
                                {
                                    errors[`slider.link_ar`]?.length > 0 && errors[`slider.link_ar`]
                                }
                            </span>
                        </div>
                        <div className={`upload_button ${errors[`slider.link_ar`]?.length > 0 && errors[`slider.link_ar`] ? 'error_border' : ''}`}>
                            <Upload
                                cb={(x) => {
                                    setlink_ar(x)
                                    //console.log(x)

                                }}
                            />
                        </div>
                        <div className={`form-control`} >
                            <FormControl >
                                <InputLabel id="demo-simple-select-label" className="partition">
                                    Type
                                <span className="image_err">
                                        {
                                            errors[`slider.slide_type`]?.length > 0 && errors[`slider.slide_type`]
                                        }
                                    </span>
                                </InputLabel>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={slide_type}
                                    onChange={
                                        (e) => {
                                            setslide_type(e.target.value)
                                        }
                                    }
                                    className={errors[`slider.slide_type`]?.length > 0 && errors[`slider.slide_type`] ? 'error_border' : ''}
                                >
                                    <MenuItem value="video">Video</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <InputBaseWithLable
                            label="Order"
                            placeholder="Order"
                            value={order}
                            onChange={(e) => {
                                setOrder(e.target.value)
                            }}
                            errorMessage={errors[`slider.order`]}
                        />
                        {
                            //      <InputBaseWithLable
                            //      label="Cover Image"
                            //      placeholder="Cover Image"
                            //      value={imgLink}
                            //      onChange={(e) => {
                            //          setImgLink(e.target.value)
                            //      }}
                            //  />
                        }
                        <div className="update_now_button">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    async () => {
                                        const updateslider = await putApiRequest(`/sliders/update/${sliderId}`, {
                                            slider: {
                                                link_ar: link_ar,
                                                link_en: link_en,
                                                slide_type: slide_type,
                                                order,
                                                location : mainSliderLocation

                                            }
                                        })
                                        //console.log('link_ar', link_ar)
                                        //console.log('link_en', link_en)
                                        //console.log('slide_type', slide_type)
                                        //console.log('order', order)
                                        if (updateslider.success) {
                                            // First way to refresh the data after updat from modal
                                            let temp = [].concat(sliders)
                                            let index = temp.findIndex((slider) => slider._id === sliderId)
                                            temp[index].link_ar = link_ar
                                            temp[index].link_en = link_en
                                            temp[index].slide_type = slide_type
                                            temp[index].order = order
                                            setsliders(temp)
                                            setModalOpen(false)
                                            setSnakBarStatus(true)
                                            setSuccessStatus('success')
                                            setAlertText('slider Updated Successfuly !')
                                            //console.log(updateslider)
                                        } else {
                                            setSnakBarStatus(true)
                                            setSuccessStatus('error')
                                            if (updateslider.errors) {
                                                let errors = {}
                                                updateslider.errors.map((err) => {
                                                    errors[err.param] = err.msg
                                                })
                                                //console.log(errors)
                                                setErrors(errors)
                                                setAlertText(`check above fields`)
                                            } else {
                                                setAlertText(`${updateslider.message}`)
                                            }
                                        }
                                    }
                                }
                            >
                                Update NOW !
                </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={addNewMenuModalFlag}
                    onClose={() => setAddNewMenuModalFlag(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal_container">
                        <div className='flex_col_center_center'> Add New Sub slider</div>
                        <div className="partition">
                            Upload English Image
                    <span className="image_err">
                                {
                                    errors[`link_en`]?.length > 0 && errors[`link_en`]

                                }
                            </span>
                        </div>
                        <div className={`upload_button ${errors[`link_en`]?.length > 0 && errors[`link_en`] ? 'error_border' : ''}`}>
                            <Upload
                                cb={(x) => {
                                    setlink_en(x)
                                    //console.log(x)

                                }}
                            />
                        </div>
                        <div className="partition">
                            Upload Arabic Image
                    <span className="image_err">
                                {
                                    errors[`link_ar`]?.length > 0 && errors[`link_ar`]
                                }
                            </span>
                        </div>
                        <div className={`upload_button ${errors[`link_ar`]?.length > 0 && errors[`link_ar`] ? 'error_border' : ''}`}>
                            <Upload
                                cb={(x) => {
                                    setlink_ar(x)
                                    //console.log(x)

                                }}
                            />
                        </div>
                        <div className={`form-control`} >
                            <FormControl >
                                <InputLabel id="demo-simple-select-label" className="partition">
                                    Type
                                <span className="image_err">
                                        {
                                            errors[`slide_type`]?.length > 0 && errors[`slide_type`]
                                        }
                                    </span>
                                </InputLabel>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={slide_type}
                                    onChange={
                                        (e) => {
                                            setslide_type(e.target.value)
                                        }
                                    }
                                    className={errors[`slide_type`]?.length > 0 && errors[`slide_type`] ? 'error_border' : ''}
                                >
                                    <MenuItem value="video">Video</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <InputBaseWithLable
                            label="Order"
                            placeholder="Order"
                            value={order}
                            onChange={(e) => {
                                setOrder(e.target.value)
                            }}
                            errorMessage={errors[`order`]}
                        />
                        <div className="update_now_button">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    async () => {
                                        const newslider = await postApiRequest('/sliders', {
                                            link_ar: link_ar,
                                            link_en: link_en,
                                            slide_type: slide_type,
                                            order,
                                            location: mainSliderLocation
                                            //slider: mainsliderId.toString()

                                        })
                                        if (newslider.success) {
                                            // First way to refresh the data after updat from modal
                                            // //console.log(mainsliderId)
                                            let temp = [].concat(sliders)
                                            temp.unshift(newslider.data.slider)
                                            setsliders(temp)
                                            setAddNewMenuModalFlag(false)
                                            setSnakBarStatus(true)
                                            setSuccessStatus('success')
                                            setAlertText('New slider has been created Successfuly !')
                                        } else {
                                            setSnakBarStatus(true)
                                            setSuccessStatus('error')
                                            if (newslider.errors) {
                                                let errors = {}
                                                newslider.errors.map((err) => {
                                                    errors[err.param] = err.msg
                                                })
                                                //console.log(errors)
                                                setErrors(errors)
                                                //console.log(" //console.log( errors[`slider.link_en`]);", errors[`link_en`]);
                                                setAlertText(`check above fields`)
                                            } else {
                                                setAlertText(`${newslider.message}`)
                                            }
                                        }
                                    }
                                }
                            >
                                Add NOW !
                </Button>
                        </div>
                    </div>
                </Modal>
                <Dialog
                    open={dialogOpen}
                    keepMounted
                    onClose={
                        () => {
                            setDialogOpen(false)
                        }
                    }
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle className='red_button'>Delete !</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Are you sure you want to delete this Sub slider ?!
          </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={
                            () => {
                                setDialogOpen(false)
                            }
                        }
                            color="primary">
                            <div className="green_button">Cancle</div>
                        </Button>
                        <Button onClick={
                            async () => {
                                const deleteSubslider = await deleteApiRequest(`/sliders/${sliderId}`)
                                if (deleteSubslider.success) {
                                    let tmp = [].concat(sliders)
                                    let temp2 = sliders.filter((slider) => slider._id !== sliderId)
                                    setsliders(temp2)
                                    setSnakBarStatus(true)
                                    setSuccessStatus('success')
                                    setAlertText('slider deleted Successfuly !')
                                } else {
                                    setSnakBarStatus(true)
                                    setSuccessStatus('error')
                                    setAlertText('Ooops....Something went Wrong !')
                                }
                                setDialogOpen(false)
                            }
                        } color="primary">
                            <div className="red_button">Delete</div>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar open={snakBarStatus} autoHideDuration={5000}
                    onClose={
                        () => {
                            setSnakBarStatus(false)
                        }
                    }
                >
                    <Alert onClose={
                        () => {
                            setSnakBarStatus(false)
                        }
                    } severity={successStatus}>
                        {alertText}
                    </Alert>
                </Snackbar>
            </div>
        </>

    )
}
export default SubSliders;