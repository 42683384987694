import React, { useRef, useState } from "react";
import { postApiRequest } from '../../../services/apiRequest';
import AWS from 'aws-sdk'
// AWS.config.update({
//     accessKeyId: "AKIA4IFTJT2VAOOTJDHC",
//     secretAccessKey: "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"
// })
// AWS.config.update({
//     accessKeyId: "AKIAQ6ZUDVUXNHE7IRO7",
//     secretAccessKey: "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"
// })

function Upload({ cb }) {
    const [isUpload, setisUpload] = useState(false)
    const [isClickUpload, setisClickUpload] = useState(false)
    const [persant, setPersant] = useState(0)

    const [disableUpload, setDisableUpload] = useState(true)

    const fileInput = useRef();
    const handleClick = async (event) => {
        if (fileInput.current.files[0]) {
            event.preventDefault();
            let file = fileInput.current.files[0];
            const formData = new FormData();
            formData.append("file", file);
            const result = await postApiRequest("/admins/v1/upload-image", formData);
            cb(`https://storage.googleapis.com/sehtak-dev/${result?.data?.result?.publicUrl}`)
            setisUpload(true)
            // let myBucket = new AWS.S3({
            //     params: { Bucket: 'tobeenglish2' },
            //     region: 'eu-central-1'
            // })
            // let pathFile = `data/${file.name}`
            // const params = {
            //     ACL: 'public-read',
            //     Key: pathFile,
            //     ContentType: file.type,
            //     Body: file
            // }
            // let req = myBucket.putObject(params);
            // req.on('build', function () {
            //     req.httpRequest.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, x-access-token';
            //     req.httpRequest.headers['Access-Control-Allow-Methods'] = 'PUT, POST, GET, DELETE, OPTIONS';
            //     req.httpRequest.headers['Access-Control-Allow-Origin'] = '*';
            // }).on('httpUploadProgress', (evt) => {
            //     setPersant(Math.round((evt.loaded / evt.total) * 100))
            // }).send((err) => {
            //     if (err) {
            //         // handle the error here
            //     } else {
            //         cb(`https://storage.googleapis.com/sehtak-dev/${pathFile}`)
            //         setisUpload(true)
            //         setisClickUpload(false)
            //     }
            // })
        }

    };
    return (
        <>
            <form className='upload-steps'>
                <input type='file' ref={fileInput} onChange={(e) => {
                    setDisableUpload(false)
                }} />
                <br />
                <div
                    style={{

                        'display': 'flex',
                        'flex-direction': 'row',
                        'justify-content': 'center',
                    }}
                >

                    <div>
                        <button type='submit' onClick={handleClick} disabled={disableUpload}>UPLOAD</button>
                        {isUpload && <div>Uploaded</div>}

                    </div>
                    {isClickUpload &&
                        <span
                            style={{
                                marginRight: 5,
                                'max-width': 60,
                                'min-width': 60,
                                width: 60
                            }}
                        >{persant} %</span>
                    }
                </div>
            </form>
        </>
    );
}

export default Upload;