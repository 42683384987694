
import { Grid, Hidden, makeStyles, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import './style.scss'
import ReactHtmlParser from 'react-html-parser';
import { useEffect, useState } from 'react';

let colorIndex = 0

function CoursesView({
  courses,
  link,
  cover_image = 'cover_image',
  onCloseCB = () => { },
  CB = () => { },

}) {
  const [lang, setLang] = useState('ar')
  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar')
    }
    let language = localStorage.getItem('lang') || 'ar'
    setLang(language)

  }, [])
  const currentURL = () => {
    return window.location.replace(window.location.href);
  }
  const colors = ['#007A53', '#004C97', '#693F23']
  const _render = (course) => {

    return (
      <div className="course" onClick={() => {
        if (link === '') {
          CB(course.video_link)
        }
      }}>
        <img src={course[cover_image]} />
        <div className="info" style={{
          'background-color': colors[colorIndex]
        }}>
          <div className="name">{course[`name_${lang}`]}</div>
          <div className={lang === 'en' ? `desc` : 'desc rtl'}>
            <div className='divider_dash_white'></div>
            {ReactHtmlParser(course[`description_${lang}`])}...
                    </div>
        </div>
      </div>
    )
  }
  return (
    <Grid container className="image_course_container" >
      {
        courses.map((course) => {
          colorIndex++
          if (colorIndex >= 3)
            colorIndex = 0
          return (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              {
                link !== '' ?
                  <Link to={`${link}${course._id}`} onClick={() => onCloseCB()}>
                    {_render(course)}
                  </Link> : <>{_render(course)}</>
              }
            </Grid>
          )
        })
      }
    </Grid >
  )
}
export default CoursesView;