import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Snackbar, useScrollTrigger } from '@material-ui/core';
import { useEffect, useState } from 'react';
import FormLayout from '../../../component/admin/formLayout';
import { deleteApiRequest, getApiRequest, postApiRequest, putApiRequest } from '../../../services/apiRequest';
import AdminLayout from '../layout';
import './style.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InputBaseWithLable from '../../../component/admin/inputBaseWithLabel';
import { Alert } from '@material-ui/lab';
function Admins({ history }) {
    const [isLoading, setIsloading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [fullName, setFullName] = useState('')
    const [admins, setAdmins] = useState([])
    const [adminId, setAdminId] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [snakBarStatus, setSnakBarStatus] = useState(false)
    const [successStatus, setSuccessStatus] = useState('')
    const [alertText, setAlertText] = useState('')
    const [addNewMenuModalFlag, setAddNewMenuModalFlag] = useState(false)
    useEffect(async () => {
        const admins = await getApiRequest('/admins/getAdmins')
        if (admins?.data) {
            setAdmins(admins.data)
            setIsloading(true)
        }
    }, [])
    return (
        isLoading &&
        <AdminLayout history={history}>
            <FormLayout name="Admins">
                <div className="menus_table_container" >
                    <div className="maenus_header_container">
                        <div className="item_header">Email</div>
                        <div className="item_header">Full Name</div>
                        <div className="item_header">Action </div>
                    </div>
                    {
                        admins && admins.map(
                            (admin, index) => {
                                return (
                                    <div key={admin._id} className={`item_container ${index % 2 !== 0 && 'background_second'}`}>
                                        <div className="item">{admin.email}</div>
                                        <div className="item">{admin.full_name}</div>
                                        <div className="item flex_row">
                                            <DeleteIcon
                                                className='cursor_pointer delete_icon'
                                                onClick={
                                                    () => {
                                                        setAdminId(admin._id)
                                                        setDialogOpen(true)
                                                    }
                                                }
                                            />
                                            <EditIcon className='cursor_pointer edit_icon'
                                                onClick={
                                                    () => {
                                                        setAdminId(admin._id)
                                                        setEmail(admin.email)
                                                        setPassword('')
                                                        setFullName(admin.full_name)
                                                        setCurrentIndex(index)
                                                        setModalOpen(true)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
                <div className="new_menu_button">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                // let tmpArray = [].concat(phoneNumbers)
                                // tmpArray.push('')
                                // setPhoneNumbers(tmpArray)
                                setAdminId('')
                                setPassword('')
                                setFullName('')
                                setEmail('')
                                setAddNewMenuModalFlag(true)
                            }
                        }

                    >
                        Add New Admin
                </Button>
                </div>
            </FormLayout>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="modal_container">
                    <InputBaseWithLable
                        label="Email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />

                    <InputBaseWithLable
                        label="Full Name"
                        placeholder="Full Name"
                        value={fullName}
                        onChange={(e) => {
                            setFullName(e.target.value)
                        }}
                    />
                    <InputBaseWithLable
                        label="Password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                    />

                    <div className="update_now_button">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                async () => {
                                    const updateadmin = await putApiRequest(`/admins/${adminId}`, {
                                        admin: {
                                            email,
                                            password,
                                            full_name: fullName

                                        }
                                    })
                                    //console.log('updateadmin', updateadmin)
                                    if (updateadmin.success) {
                                        // First way to refresh the data after updat from modal
                                        let temp = [].concat(admins)
                                        let index = temp.findIndex((admin) => admin._id === adminId)
                                        temp[index].email = email
                                        temp[index].password = password
                                        temp[index].full_name = fullName
                                        setAdmins(temp)
                                        setModalOpen(false)
                                        setSnakBarStatus(true)
                                        setSuccessStatus('success')
                                        setAlertText('Admin Updated Successfuly !')
                                    } else {
                                        setSnakBarStatus(true)
                                        setSuccessStatus('error')
                                        setAlertText(`${updateadmin.message}`)
                                    }
                                }
                            }
                        >
                            Update NOW !
                </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={addNewMenuModalFlag}
                onClose={() => setAddNewMenuModalFlag(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="modal_container">
                    <div className='flex_col_center_center'> Add New Admin</div>
                    <InputBaseWithLable
                        label="Email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />

                    <InputBaseWithLable
                        label="Full Name"
                        placeholder="Full Name"
                        value={fullName}
                        onChange={(e) => {
                            setFullName(e.target.value)
                        }}
                    />
                    <InputBaseWithLable
                        label="Password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                    />
                    <div className="update_now_button">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                async () => {
                                    const newAdmin = await postApiRequest('/admins', {
                                        email,
                                        password,
                                        full_name: fullName
                                    })
                                    if (newAdmin.success) {
                                        // First way to refresh the data after updat from modal
                                        let temp = [].concat(admins)
                                        temp.push(newAdmin.data.admin)
                                        setAdmins(temp)
                                        setAddNewMenuModalFlag(false)
                                        setSnakBarStatus(true)
                                        setSuccessStatus('success')
                                        setAlertText('New Admin has been created Successfuly !')
                                    } else {
                                        setSnakBarStatus(true)
                                        setSuccessStatus('error')
                                        setAlertText(`${newAdmin.message}`)
                                    }
                                }
                            }
                        >
                            Add NOW !
                </Button>
                    </div>
                </div>
            </Modal>
            <Dialog
                open={dialogOpen}
                keepMounted
                onClose={
                    () => {
                        setDialogOpen(false)
                    }
                }
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className='red_button'>Delete !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this Admin ?!
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={
                        () => {
                            setDialogOpen(false)
                        }
                    }
                        color="primary">
                        <div className="green_button">Cancle</div>
                    </Button>
                    <Button onClick={
                        async () => {
                            const deleteAdmin = await deleteApiRequest(`/admins/${adminId}`)
                            if (deleteAdmin.success) {
                                let tmp = [].concat(admins)
                                let temp2 = admins.filter((admin) => admin._id !== adminId)
                                setAdmins(temp2)
                                setSnakBarStatus(true)
                                setSuccessStatus('success')
                                setAlertText('Admin deleted Successfuly !')
                            } else {
                                setSnakBarStatus(true)
                                setSuccessStatus('error')
                                setAlertText('Ooops....Something went Wrong !')
                            }
                            setDialogOpen(false)
                        }
                    } color="primary">
                        <div className="red_button">Delete</div>
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snakBarStatus} autoHideDuration={5000}
                onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                }
            >
                <Alert onClose={
                    () => {
                        setSnakBarStatus(false)
                    }
                } severity={successStatus}>
                    {alertText}
                </Alert>
            </Snackbar>
        </AdminLayout>
    )
}
export default Admins;