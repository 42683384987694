import "./admin_test.scss";
import { Button, TextField } from "@material-ui/core";
import { useState } from "react";
import { postApiRequest } from "../services/apiRequest";
function AdminSubCourse() {
    const [nameAr, setNameAr] = useState('')
    const [nameEn, setNameEn] = useState('')
    const [descriptionAr, setDescriptionAr] = useState('')
    const [descriptionEn, setDescriptionEn] = useState('')
    const [order, setOrder] = useState(0)
    const [coverImage, setCoverImage] = useState('')
    const [course, setCourse] = useState('')
    return (
        <div className="admin_test_container">
            <TextField
                label="name_ar"
                onChange={
                    (e) => {
                        setNameAr(e.target.value)
                    }
                }
            />
            <TextField
                label="name_en"
                onChange={
                    (e) => {
                        setNameEn(e.target.value)
                    }
                }
            />
            <TextField
                label="description_ar"
                multiline
                onChange={
                    (e) => {
                        setDescriptionAr(e.target.value)
                    }
                }
            />
            <TextField
                label="description_en"
                multiline
                onChange={
                    (e) => {
                        setDescriptionEn(e.target.value)
                    }
                }
            />
            <TextField
                label="order"
                onChange={
                    (e) => {
                        setOrder(e.target.value)
                    }
                }
            />
            <TextField
                label="cover_image"
                onChange={
                    (e) => {
                        setCoverImage(e.target.value)
                    }
                }
            />
            <TextField
                label="course"
                onChange={
                    (e) => {
                        setCourse(e.target.value)
                    }
                }
            />
            <Button
            variant="contained"
            onClick = {
                async() => {
                    const SubCourse = await postApiRequest("/Sub-course",
                        {
                            name_ar: nameAr ,
                            name_en: nameEn , 
                            description_ar: descriptionAr , 
                            description_en: descriptionEn , 
                            order, 
                            cover_image: coverImage , 
                            course
                        }
                    )
                    //console.log("English Name" , nameEn)
                }
            } >
                Add Sub Course
                </Button>
        </div>
    )
}
export default AdminSubCourse;