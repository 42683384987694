import { useEffect, useRef, useState } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './style.scss';
import { Hidden } from '@material-ui/core';
import ReactPlayer from 'react-player';
function SlideShow({ sliders }) {
    const [index, setIndex] = useState(0);
    const [mute, setMute] = useState(true)
    const [playing, setPlaying] = useState(true)
    const videoRef = useRef(null)
    const [lang, setLang] = useState('ar')
  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar')
    }
    let language = localStorage.getItem('lang') || 'ar'
    setLang(language)
  }, [])
    useEffect(() => {
        if (index !== 0) {
            setTimeout(() => {
                if (index + 1 === 5) {
                    setIndex(0)
                    videoRef.current.seekTo(0, 'seconds')
                } else {
                    setIndex(index + 1)
                }
            }, 3000)
        } else {
            setPlaying(true)
        }
    }, [index])
    return (
        <div className="slideshow">
            <div
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                className="slideshowSlider"
            >
                {sliders.map((slider) => {
                    return (
                        <div className="slide" >
                            {
                                slider.slide_type === 'image' ?
                                    <img src={slider[`link_${lang}`]} alt='' /> :
                                    <ReactPlayer
                                        ref={videoRef}
                                        url={slider[`link_${lang}`]}
                                        width="100%"
                                        height="100%"
                                        playing={playing}
                                        controls
                                        volume={1}
                                        muted={mute}
                                        onEnded={
                                            () => {
                                                setIndex(index + 1)
                                            }
                                        }
                                        onSeek={() => {
                                         //   setMute(false)
                                        }}
                                        onClick={() => {
                                            setMute(false)
                                        }}
                                    />
                            }
                        </div>
                    )
                })}
            </div>
            <Hidden only={['sm', 'xs']} >
                <div className="slideshowDots">
                    <div
                        className='margin_left_arrow'
                        onClick={() => {
                            if (index - 1 < 0) {
                                setIndex(sliders.length - 1)

                            } else {
                                setIndex(index - 1)
                            }

                        }
                        }> <ArrowBackIcon className="slide_arrow" /> </div>
                    {sliders.map((_, idx) => (
                        <div key={idx}
                            className={`slideshowDot${index === idx ? " active" : ""}`}
                            onClick={() => {
                                setIndex(idx);
                            }}
                        >
                        </div>
                    ))}
                    <div
                        className='margin_right_arrow'
                        onClick={() => {
                            if ((index + 1) === sliders.length) {
                                setIndex(0)
                            } else {
                                setIndex(index + 1)
                            }

                        }}> <ArrowForwardIcon className="slide_arrow" /> </div>
                </div>
            </Hidden>
            <Hidden only={['md', 'lg','xl']} >
                <div className="slideshowDots_mobile">
                    <div
                        className='margin_left_arrow'
                        onClick={() => {
                            if (index - 1 < 0) {
                                setIndex(sliders.length - 1)

                            } else {
                                setIndex(index - 1)
                            }

                        }
                        }> <ArrowBackIcon className="slide_arrow" /> </div>
                    <div
                        className='margin_right_arrow'
                        onClick={() => {
                            if ((index + 1) === sliders.length) {
                                setIndex(0)
                            } else {
                                setIndex(index + 1)
                            }

                        }}> <ArrowForwardIcon className="slide_arrow" /> </div>
                </div>
            </Hidden>

        </div >
    );

}
export default SlideShow;