import { useEffect, useState } from 'react';
import { getApiRequest } from '../../services/apiRequest';
import { getQuery, RedirectToAdmin } from '../../services/utility';
import './style.scss';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuIcon from '@material-ui/icons/Menu';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MessageIcon from '@material-ui/icons/Message';
import { Link } from 'react-router-dom';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
const getLink = () => {
    const link = window.location.href
    const psths = link.split('/')
    return psths[psths.length - 1]
}
const menus = [
    // {
    //     label: 'Dashboard',
    //     icon: <DashboardIcon className='icon_color' />,
    //     path: ''
    // }, 
    {
        label: 'Settings',
        icon: <SettingsIcon className='icon_color' />,
        path: 'settings'
    }, {
        label: 'Menus',
        icon: <MenuIcon className='icon_color' />,
        path: 'menus'
    }, {
        label: 'Admins',
        icon: <SupervisorAccountIcon className='icon_color' />,
        path: 'admins'
    }, {
        label: 'Employees',
        icon: <RecentActorsIcon className='icon_color' />,
        path: 'employees'
    }, {
        label: 'Articles',
        icon: <AssignmentIcon className='icon_color' />,
        path: 'articles'
    }, {
        label: 'Courses',
        icon: <MenuBookIcon className='icon_color' />,
        path: 'courses'
    }, {
        label: 'Sliders',
        icon: <PhotoLibraryIcon className='icon_color' />,
        path: 'sliders'
    }, {
        label: 'Message Center',
        icon: <MenuBookIcon className='icon_color' />,
        path: 'message-center'
    }, {
        label: 'People Opinion',
        icon: <ThumbsUpDownIcon className='icon_color' />,
        path: 'people-opinion'
    }, {
        label: 'Questions',
        icon: <ContactSupportIcon className='icon_color' />,
        path: 'questions'
    },
    {
        label: 'Assessment Test',
        icon: <LiveHelpIcon className='icon_color' />,
        path: 'assessment-test'
    }


]
function AdminLayout({ history, children }) {
    const [isLogIn, setIsLogIn] = useState(false)
    useEffect(async () => {
        if (localStorage.getItem('status') == 'true') {
            const validTokin = await getApiRequest('/admins/is-valid')
            if (validTokin?.data?.success) {
                setIsLogIn(true)
            } else {
                RedirectToAdmin({ history })
            }
        } else {
            RedirectToAdmin({ history })
        }
    }, [])
    // //console.log('getLink()', getLink())
    return (
        isLogIn && <>
            <div className="admin_container">
                <div className="left_side_panel_container">
                    <div className="company_name"> <span className="b_color">To Be</span> <span className="w_color">English</span> </div>
                    <div className="manage_container">
                        {
                            menus.map((item) => {
                                return (
                                    <Link to={`/admin/${item.path}`} className={`${(getLink() === item.path) || (getLink() === 'admin' && item.label === 'Dashboard') ? 'item_active' : 'item'}`}>
                                        {item.icon}
                                        <div className="label">{item.label}</div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='admin_body_container'>
                    {children}
                </div>
            </div>
        </>
    )
}
export default AdminLayout;