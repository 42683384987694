import './style.scss';
import { useEffect, useState } from 'react';
import { Slide, SwipeableDrawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { getApiRequest } from '../../services/apiRequest';
import CoursesView from '../coursesView';
const ArticleDrawer = ({
    isOpen,
    first_time,
    onCloseCB
}) => {
    const [articles, setArticles] = useState([])
    const [isloading, setIsLoading] = useState(false)

    useEffect(async () => {
        const article = await getApiRequest("/articles/last-three")
        if (article?.data) {
            setArticles(article.data)
            setIsLoading(true)
        }
    }, []
    )
    return (
        isloading &&
        <div className={`article_drawer_container ${!first_time && (isOpen ? 'article_drawer_show' : 'article_drawer_hide')}`}>
            <div className={`article_container`}>
                <CoursesView onCloseCB={onCloseCB} cover_image={'image_link'} courses={articles} link="article?id=" />
            </div>
        </div>
    );
}

export default ArticleDrawer;
