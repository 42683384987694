import "./admin_test.scss";
import { Button, TextField } from "@material-ui/core";
import { useState } from "react";
import { postApiRequest } from "../services/apiRequest";
import AdminLayout from "../pages/admin/layout";
function AdminTest({ history }) {
    const [titleEn, setTitleEn] = useState('')
    const [titleAr, setTitleAr] = useState('')
    const [descriptionAr, setDescriptionAr] = useState('')
    const [descriptionEn, setDescriptionEn] = useState('')
    const [location, setLoaction] = useState('')
    const [order, setOrder] = useState(0)
    return (
        <AdminLayout history={history}>
            <div className="admin_test_container">
                <TextField
                    id="standard-basic"
                    label="title_en"
                    onChange={(event) => {
                        setTitleEn(event.target.value)
                    }}
                />
                <TextField id="standard-basic"
                    label="title_ar"
                    onChange={(event) => {
                        setTitleAr(event.target.value)
                    }
                    }
                />
                <TextField id="standard-basic"
                    label="description_ar"
                    multiline rowsMax={4}
                    rows={4}
                    onChange={(event) => {
                        setDescriptionAr(event.target.value)
                    }}
                />
                <TextField
                    id="standard-basic"
                    label="description_en"
                    multiline
                    rowsMax={4}
                    rows={4}
                    onChange={(event) => {
                        setDescriptionEn(event.target.value)
                    }}
                />
                <TextField
                    id="standard-basic"
                    label="location"
                    onChange={(event) => {
                        setLoaction(event.target.value)
                    }}
                />
                <TextField
                    id="standard-basic"
                    label="order"
                    type="number"
                    onChange={(event) => {
                        setOrder(event.target.value)
                    }}
                />
                <Button variant="contained"
                    onClick={async () => {
                        const paragraph = await postApiRequest("/paragraphs", {
                            title_en: titleEn,
                            title_ar: titleAr,
                            description_ar: descriptionAr,
                            description_en: descriptionEn,
                            location,
                            order
                        })
                        //console.log('titleEn', titleEn)
                    }}
                >Add paragraph</Button>
            </div>
        </AdminLayout>
    )
}
export default AdminTest;