import LeftSidePanel from '../leftSidePanel';
import MobileHeader from '../mobileHeader';
import { Hidden } from '@material-ui/core';
import { getApiRequest } from '../../services/apiRequest';
import { useEffect, useState } from 'react';

function Header({ settings }) {
    const [menus, setMenus] = useState([])
    const [courses, setCourses] = useState([])
    useEffect(async () => {
        const menu = await getApiRequest("/menus")
        if (menu?.data) {
            setMenus(menu.data)
        }
        const courses = await getApiRequest("/courses")
        if(courses?.data)
        {
            setCourses(courses.data)
        }
    }, [])
    return (
        <>
            <Hidden only={['md', 'lg','xl']}>
                <MobileHeader settings={settings} menus={menus} courses={courses} />
            </Hidden>
            <Hidden only={['xs', 'sm']}>
                <LeftSidePanel menus={menus} courses={courses}/>
            </Hidden>
        </>
    );
}

export default Header;
